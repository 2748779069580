import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { client } from './client';
import SearchPage from './SearchPage';
import SearchBar from './SearchBar';
import SearchProvider from '../context/SearchContext';
import useWindowWidth from '../hooks/useWindowWidth';

export default function SearchContainer({
  restaurant_categories,
  all_else_categories,
}) {
  const width = useWindowWidth();

  return (
    <ApolloProvider client={client}>
      <SearchProvider
        restaurant_categories={restaurant_categories}
        all_else_categories={all_else_categories}
      >
        {width <= 975
          ? ReactDOM.createPortal(
              <SearchPage />,
              document.querySelector('#search-page-portal')
            )
          : ReactDOM.createPortal(
              <SearchBar />,
              document.querySelector('#search-bar-portal')
            )}
      </SearchProvider>
    </ApolloProvider>
  );
}
