export const rentalSearchDefaults = [
  { name: 'destin', video_code: null },
  { name: '30a', video_code: null },
  { name: 'fort-walton-beach', video_code: null },
  { name: 'miramar-beach', video_code: null },
];

export const restaurantSearchDefaults = [
  { name: 'destin', video_code: null },
  { name: '30a', video_code: null },
  { name: 'fort-walton-beach', video_code: null },
  { name: 'miramar-beach', video_code: null },
];

export const realEstateSearchDefaults = [
  { name: 'Destin', slug: 'destin' },
  { name: '30a', slug: '30a_west,30a_east' },
  { name: 'Fort Walton Beach', slug: 'fort_walton_beach' },
  { name: 'Miramar Beach', slug: 'miramar_sandestin_resort' },
];

export const allElseSearchDefaults = [
  { name: 'destin', video_code: null },
  { name: '30a', video_code: null },
  { name: 'fort-walton-beach', video_code: null },
  { name: 'miramar-beach', video_code: null },
];
