import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';
import { decode } from 'html-decoder';
import CustomClose from './CustomToast/CustomClose';
import FavoriteSuccessBanner from './CustomToast/FavoriteSuccessBanner';

// Create a custom event (Detail is required to pass custom data)
//   const event = new CustomEvent('toast-message', { detail: 'Test Message' });
// Dispatch custom event
//   document.dispatchEvent(event)

function Toast() {
  useEffect(() => {
    const handleToastMessage = e => {
      toast.dismiss();
      toast(decode(e.detail), { className: 'global-toast' });
    };

    const handleGroupToastMessage = e => {
      toast(decode(e.detail), {
        className: 'group-toast global-toast',
      });
    };

    const handleFavoriteToast = e => {
      // e.detail = { type: { ...property,listing,event }, favorited: bool }
      toast.dismiss();
      if (e.detail.favorited)
        toast(<FavoriteSuccessBanner favorited type={e.detail.type} />);
      else toast(<FavoriteSuccessBanner type={e.detail.type} />);
    };

    document.addEventListener('toast-message', handleToastMessage);
    document.addEventListener('group-toast-message', handleGroupToastMessage);
    document.addEventListener('favorite-toast', handleFavoriteToast);

    return () => {
      document.removeEventListener('toast-message', handleToastMessage);
      document.removeEventListener('favorite-toast', handleFavoriteToast);
    };
  }, []);

  return (
    <ToastContainer
      autoClose={5000}
      closeOnClick={false}
      draggable
      position="bottom-right"
      hideProgressBar
      newestOnTop
      closeButton={<CustomClose />}
    />
  );
}

export default Toast;

// Toast.propTypes = {
//   messages: PropTypes.array,
// };
