import React from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import heart_filled from 'heart_filled.svg';
import heart_unfavorite from 'heart_path.svg';

export default function FavoriteSuccessBanner({ type, favorited }) {
  let toast_image = '';
  // Event
  if (type.listing) toast_image = type.small_featured_image_url;
  // Property
  else if (type.property_score)
    toast_image =
      type.image_url || type.images[0].url || type.images[0].table.url;
  // Real Estate
  else if (type.sub_type)
    toast_image = type.feature_image_url || type.images[0];
  // Listing
  else toast_image = type.small_featured_image_url;

  return (
    <div className="favorite-success-banner">
      <section
        className="favorite-success-image"
        style={{ backgroundImage: toast_image && `url('${toast_image}')` }}
      >
        <ReactSVG src={favorited ? heart_filled : heart_unfavorite} />
      </section>
      <h3>
        {favorited ? 'Added to My Favorites' : 'Removed from My Favorites'}
      </h3>
    </div>
  );
}

FavoriteSuccessBanner.propTypes = {
  type: PropTypes.object.isRequired,
  favorited: PropTypes.bool,
};
