import React from 'react';
import PropTypes from 'prop-types';

export default function FilterButton({ children, className, ...props }) {
  return (
    <button
      name={`Show ${children} filters`}
      {...props}
      type="button"
      className={`btn filter-button btn--light ${className}`}
    >
      {children}
    </button>
  );
}

FilterButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
