import React from 'react';
import PropTypes from 'prop-types';

export default function Pill({ children, className, isRedPill, ...props }) {
  return (
    <div className={`pill ${className} ${isRedPill ? 'red' : ''}`} {...props}>
      {children}
    </div>
  );
}

Pill.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  isRedPill: PropTypes.bool,
};
