/* eslint-disable */
(function() {
  let AbstractChosen;
  const __bind = function(fn, me) {
    return function() {
      return fn.apply(me, arguments);
    };
  };

  AbstractChosen = (function() {
    function AbstractChosen(_at_form_field, _at_options) {
      this.form_field = _at_form_field;
      this.options = _at_options != null ? _at_options : {};
      this.label_click_handler = __bind(this.label_click_handler, this);
      this.is_multiple = this.form_field.multiple;
      this.set_default_text();
      this.set_default_values();
      this.setup();
      this.set_up_html();
      this.register_observers();
      this.on_ready();
    }

    AbstractChosen.prototype.set_default_values = function() {
      this.click_test_action = (function(_this) {
        return function(evt) {
          return _this.test_active_click(evt);
        };
      })(this);
      this.activate_action = (function(_this) {
        return function(evt) {
          return _this.activate_field(evt);
        };
      })(this);
      this.active_field = false;
      this.mouse_on_container = false;
      this.results_showing = false;
      this.result_highlighted = null;
      this.is_rtl =
        this.options.rtl || /\bchosen-rtl\b/.test(this.form_field.className);
      this.allow_single_deselect =
        this.options.allow_single_deselect != null &&
        this.form_field.options[0] != null &&
        this.form_field.options[0].text === ''
          ? this.options.allow_single_deselect
          : false;
      this.disable_search_threshold =
        this.options.disable_search_threshold || 0;
      this.disable_search = this.options.disable_search || false;
      this.enable_split_word_search =
        this.options.enable_split_word_search != null
          ? this.options.enable_split_word_search
          : true;
      this.group_search =
        this.options.group_search != null ? this.options.group_search : true;
      this.search_contains = this.options.search_contains || false;
      this.single_backstroke_delete =
        this.options.single_backstroke_delete != null
          ? this.options.single_backstroke_delete
          : true;
      this.max_selected_options = this.options.max_selected_options || Infinity;
      this.inherit_select_classes =
        this.options.inherit_select_classes || false;
      this.display_selected_options =
        this.options.display_selected_options != null
          ? this.options.display_selected_options
          : true;
      this.display_disabled_options =
        this.options.display_disabled_options != null
          ? this.options.display_disabled_options
          : true;
      this.include_group_label_in_selected =
        this.options.include_group_label_in_selected || false;
      this.max_shown_results =
        this.options.max_shown_results || Number.POSITIVE_INFINITY;
      this.case_sensitive_search = this.options.case_sensitive_search || false;
      return (this.hide_results_on_select =
        this.options.hide_results_on_select != null
          ? this.options.hide_results_on_select
          : true);
    };

    AbstractChosen.prototype.set_default_text = function() {
      if (this.form_field.getAttribute('data-placeholder')) {
        this.default_text = this.form_field.getAttribute('data-placeholder');
      } else if (this.is_multiple) {
        this.default_text =
          this.options.placeholder_text_multiple ||
          this.options.placeholder_text ||
          AbstractChosen.default_multiple_text;
      } else {
        this.default_text =
          this.options.placeholder_text_single ||
          this.options.placeholder_text ||
          AbstractChosen.default_single_text;
      }
      this.default_text = this.escape_html(this.default_text);
      return (this.results_none_found =
        this.form_field.getAttribute('data-no_results_text') ||
        this.options.no_results_text ||
        AbstractChosen.default_no_result_text);
    };

    AbstractChosen.prototype.choice_label = function(item) {
      if (this.include_group_label_in_selected && item.group_label != null) {
        return `<b class='group-name'>${this.escape_html(
          item.group_label
        )}</b>${item.html}`;
      }
      return item.html;
    };

    AbstractChosen.prototype.mouse_enter = function() {
      return (this.mouse_on_container = true);
    };

    AbstractChosen.prototype.mouse_leave = function() {
      return (this.mouse_on_container = false);
    };

    AbstractChosen.prototype.input_focus = function(evt) {
      if (this.is_multiple) {
        if (!this.active_field) {
          return setTimeout(
            (function(_this) {
              return function() {
                return _this.container_mousedown();
              };
            })(this),
            50
          );
        }
      } else if (!this.active_field) {
        return this.activate_field();
      }
    };

    AbstractChosen.prototype.input_blur = function(evt) {
      if (!this.mouse_on_container) {
        this.active_field = false;
        return setTimeout(
          (function(_this) {
            return function() {
              return _this.blur_test();
            };
          })(this),
          100
        );
      }
    };

    AbstractChosen.prototype.label_click_handler = function(evt) {
      if (this.is_multiple) {
        return this.container_mousedown(evt);
      }
      return this.activate_field();
    };

    AbstractChosen.prototype.results_option_build = function(options) {
      let content;
      let data;
      let data_content;
      let shown_results;
      let _i;
      let _len;
      let _ref;
      content = '';
      shown_results = 0;
      _ref = this.results_data;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        data = _ref[_i];
        data_content = '';
        if (data.group) {
          data_content = this.result_add_group(data);
        } else {
          data_content = this.result_add_option(data);
        }
        if (data_content !== '') {
          shown_results++;
          content += data_content;
        }
        if (options != null ? options.first : void 0) {
          if (data.selected && this.is_multiple) {
            this.choice_build(data);
          } else if (data.selected && !this.is_multiple) {
            this.single_set_selected_text(this.choice_label(data));
          }
        }
        if (shown_results >= this.max_shown_results) {
          break;
        }
      }
      return content;
    };

    AbstractChosen.prototype.result_add_option = function(option) {
      let classes;
      let option_el;
      if (!option.search_match) {
        return '';
      }
      if (!this.include_option_in_results(option)) {
        return '';
      }
      classes = [];
      if (!option.disabled && !(option.selected && this.is_multiple)) {
        classes.push('active-result');
      }
      if (option.disabled && !(option.selected && this.is_multiple)) {
        classes.push('disabled-result');
      }
      if (option.selected) {
        classes.push('result-selected');
      }
      if (option.group_array_index != null) {
        classes.push('group-option');
      }
      if (option.classes !== '') {
        classes.push(option.classes);
      }
      option_el = document.createElement('li');
      option_el.className = classes.join(' ');
      if (option.style) {
        option_el.style.cssText = option.style;
      }
      option_el.setAttribute('data-option-array-index', option.array_index);
      option_el.innerHTML = option.highlighted_html || option.html;
      if (option.title) {
        option_el.title = option.title;
      }
      return this.outerHTML(option_el);
    };

    AbstractChosen.prototype.result_add_group = function(group) {
      let classes;
      let group_el;
      if (!(group.search_match || group.group_match)) {
        return '';
      }
      if (!(group.active_options > 0)) {
        return '';
      }
      classes = [];
      classes.push('group-result');
      if (group.classes) {
        classes.push(group.classes);
      }
      group_el = document.createElement('li');
      group_el.className = classes.join(' ');
      group_el.innerHTML =
        group.highlighted_html || this.escape_html(group.label);
      if (group.title) {
        group_el.title = group.title;
      }
      return this.outerHTML(group_el);
    };

    AbstractChosen.prototype.results_update_field = function() {
      this.set_default_text();
      if (!this.is_multiple) {
        this.results_reset_cleanup();
      }
      this.result_clear_highlight();
      this.results_build();
      if (this.results_showing) {
        return this.winnow_results();
      }
    };

    AbstractChosen.prototype.reset_single_select_options = function() {
      let result;
      let _i;
      let _len;
      let _ref;
      let _results;
      _ref = this.results_data;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        result = _ref[_i];
        if (result.selected) {
          _results.push((result.selected = false));
        } else {
          _results.push(void 0);
        }
      }
      return _results;
    };

    AbstractChosen.prototype.results_toggle = function() {
      if (this.results_showing) {
        return this.results_hide();
      }
      return this.results_show();
    };

    AbstractChosen.prototype.results_search = function(evt) {
      if (this.results_showing) {
        return this.winnow_results();
      }
      return this.results_show();
    };

    AbstractChosen.prototype.winnow_results = function(options) {
      let escapedQuery;
      let fix;
      let option;
      let prefix;
      let query;
      let regex;
      let results;
      let results_group;
      let search_match;
      let startpos;
      let suffix;
      let text;
      let _i;
      let _len;
      let _ref;
      this.no_results_clear();
      results = 0;
      query = this.get_search_text();
      escapedQuery = query.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
      regex = this.get_search_regex(escapedQuery);
      _ref = this.results_data;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        option = _ref[_i];
        option.search_match = false;
        results_group = null;
        search_match = null;
        option.highlighted_html = '';
        if (this.include_option_in_results(option)) {
          if (option.group) {
            option.group_match = false;
            option.active_options = 0;
          }
          if (
            option.group_array_index != null &&
            this.results_data[option.group_array_index]
          ) {
            results_group = this.results_data[option.group_array_index];
            if (
              results_group.active_options === 0 &&
              results_group.search_match
            ) {
              results += 1;
            }
            results_group.active_options += 1;
          }
          text = option.group ? option.label : option.text;
          if (!(option.group && !this.group_search)) {
            search_match = this.search_string_match(text, regex);
            option.search_match = search_match != null;
            if (option.search_match && !option.group) {
              results += 1;
            }
            if (option.search_match) {
              if (query.length) {
                startpos = search_match.index;
                prefix = text.slice(0, startpos);
                fix = text.slice(startpos, startpos + query.length);
                suffix = text.slice(startpos + query.length);
                option.highlighted_html = `${this.escape_html(
                  prefix
                )}<em>${this.escape_html(fix)}</em>${this.escape_html(suffix)}`;
              }
              if (results_group != null) {
                results_group.group_match = true;
              }
            } else if (
              option.group_array_index != null &&
              this.results_data[option.group_array_index].search_match
            ) {
              option.search_match = true;
            }
          }
        }
      }
      this.result_clear_highlight();
      if (results < 1 && query.length) {
        this.update_results_content('');
        return this.no_results(query);
      }
      this.update_results_content(this.results_option_build());
      if (!(options != null ? options.skip_highlight : void 0)) {
        return this.winnow_results_set_highlight();
      }
    };

    AbstractChosen.prototype.get_search_regex = function(
      escaped_search_string
    ) {
      let regex_flag;
      let regex_string;
      regex_string = this.search_contains
        ? escaped_search_string
        : `(^|\\s|\\b)${escaped_search_string}[^\\s]*`;
      if (!(this.enable_split_word_search || this.search_contains)) {
        regex_string = `^${regex_string}`;
      }
      regex_flag = this.case_sensitive_search ? '' : 'i';
      return new RegExp(regex_string, regex_flag);
    };

    AbstractChosen.prototype.search_string_match = function(
      search_string,
      regex
    ) {
      let match;
      match = regex.exec(search_string);
      if (!this.search_contains && (match != null ? match[1] : void 0)) {
        match.index += 1;
      }
      return match;
    };

    AbstractChosen.prototype.choices_count = function() {
      let option;
      let _i;
      let _len;
      let _ref;
      if (this.selected_option_count != null) {
        return this.selected_option_count;
      }
      this.selected_option_count = 0;
      _ref = this.form_field.options;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        option = _ref[_i];
        if (option.selected) {
          this.selected_option_count += 1;
        }
      }
      return this.selected_option_count;
    };

    AbstractChosen.prototype.choices_click = function(evt) {
      evt.preventDefault();
      this.activate_field();
      if (!(this.results_showing || this.is_disabled)) {
        return this.results_show();
      }
    };

    AbstractChosen.prototype.keydown_checker = function(evt) {
      let stroke;
      let _ref;
      stroke = (_ref = evt.which) != null ? _ref : evt.keyCode;
      this.search_field_scale();
      if (stroke !== 8 && this.pending_backstroke) {
        this.clear_backstroke();
      }
      switch (stroke) {
        case 8:
          this.backstroke_length = this.get_search_field_value().length;
          break;
        case 9:
          if (this.results_showing && !this.is_multiple) {
            this.result_select(evt);
          }
          this.mouse_on_container = false;
          break;
        case 13:
          if (this.results_showing) {
            evt.preventDefault();
          }
          break;
        case 27:
          if (this.results_showing) {
            evt.preventDefault();
          }
          break;
        case 32:
          if (this.disable_search) {
            evt.preventDefault();
          }
          break;
        case 38:
          evt.preventDefault();
          this.keyup_arrow();
          break;
        case 40:
          evt.preventDefault();
          this.keydown_arrow();
          break;
      }
    };

    AbstractChosen.prototype.keyup_checker = function(evt) {
      let stroke;
      let _ref;
      stroke = (_ref = evt.which) != null ? _ref : evt.keyCode;
      this.search_field_scale();
      switch (stroke) {
        case 8:
          if (
            this.is_multiple &&
            this.backstroke_length < 1 &&
            this.choices_count() > 0
          ) {
            this.keydown_backstroke();
          } else if (!this.pending_backstroke) {
            this.result_clear_highlight();
            this.results_search();
          }
          break;
        case 13:
          evt.preventDefault();
          if (this.results_showing) {
            this.result_select(evt);
          }
          break;
        case 27:
          if (this.results_showing) {
            this.results_hide();
          }
          break;
        case 9:
        case 16:
        case 17:
        case 18:
        case 38:
        case 40:
        case 91:
          break;
        default:
          this.results_search();
          break;
      }
    };

    AbstractChosen.prototype.clipboard_event_checker = function(evt) {
      if (this.is_disabled) {
        return;
      }
      return setTimeout(
        (function(_this) {
          return function() {
            return _this.results_search();
          };
        })(this),
        50
      );
    };

    AbstractChosen.prototype.container_width = function() {
      if (this.options.width != null) {
        return this.options.width;
      }
      return `${this.form_field.offsetWidth}px`;
    };

    AbstractChosen.prototype.include_option_in_results = function(option) {
      if (
        this.is_multiple &&
        (!this.display_selected_options && option.selected)
      ) {
        return false;
      }
      if (!this.display_disabled_options && option.disabled) {
        return false;
      }
      if (option.empty) {
        return false;
      }
      return true;
    };

    AbstractChosen.prototype.search_results_touchstart = function(evt) {
      this.touch_started = true;
      return this.search_results_mouseover(evt);
    };

    AbstractChosen.prototype.search_results_touchmove = function(evt) {
      this.touch_started = false;
      return this.search_results_mouseout(evt);
    };

    AbstractChosen.prototype.search_results_touchend = function(evt) {
      if (this.touch_started) {
        return this.search_results_mouseup(evt);
      }
    };

    AbstractChosen.prototype.outerHTML = function(element) {
      let tmp;
      if (element.outerHTML) {
        return element.outerHTML;
      }
      tmp = document.createElement('div');
      tmp.appendChild(element);
      return tmp.innerHTML;
    };

    AbstractChosen.prototype.get_single_html = function() {
      return `<a class="chosen-single chosen-default">\n  <span>${this.default_text}</span>\n  <div><b></b></div>\n</a>\n<div class="chosen-drop">\n  <div class="chosen-search">\n    <input class="chosen-search-input" type="text" autocomplete="off" />\n  </div>\n  <ul class="chosen-results"></ul>\n</div>`;
    };

    AbstractChosen.prototype.get_multi_html = function() {
      return `<ul class="chosen-choices">\n  <li class="search-field">\n    <input class="chosen-search-input" type="text" autocomplete="off" value="${this.default_text}" />\n  </li>\n</ul>\n<div class="chosen-drop">\n  <ul class="chosen-results"></ul>\n</div>`;
    };

    AbstractChosen.prototype.get_no_results_html = function(terms) {
      return `<li class="no-results">\n  ${
        this.results_none_found
      } <span>${this.escape_html(terms)}</span>\n</li>`;
    };

    AbstractChosen.browser_is_supported = function() {
      if (window.navigator.appName === 'Microsoft Internet Explorer') {
        return document.documentMode >= 8;
      }
      if (
        /iP(od|hone)/i.test(window.navigator.userAgent) ||
        /IEMobile/i.test(window.navigator.userAgent) ||
        /Windows Phone/i.test(window.navigator.userAgent) ||
        /BlackBerry/i.test(window.navigator.userAgent) ||
        /BB10/i.test(window.navigator.userAgent) ||
        /Android.*Mobile/i.test(window.navigator.userAgent)
      ) {
        return false;
      }
      return true;
    };

    AbstractChosen.default_multiple_text = 'Select Categories';

    AbstractChosen.default_single_text = 'Select';

    AbstractChosen.default_no_result_text = 'No results match';

    return AbstractChosen;
  })();

  window.AbstractChosen = AbstractChosen;
}.call(this));
