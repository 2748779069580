import React from 'react';
import PropTypes from 'prop-types';

export default function CustomClose({ closeToast }) {
  return (
    <svg
      onClick={closeToast}
      className="custom-close-toast"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="0.646447"
        y1="12.6464"
        x2="12.6464"
        y2="0.646446"
        stroke="#073655"
      />
      <line
        y1="-0.5"
        x2="16.9706"
        y2="-0.5"
        transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 12 13)"
        stroke="#073655"
      />
    </svg>
  );
}

CustomClose.propTypes = {
  closeToast: PropTypes.func,
};
