import React from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import marker from 'map_marker.svg';
import rentals from 'building.svg';
import categories from 'categories.svg';
import home from 'home.svg';
import communitiesIcon from 'communities-icon.svg';

export default function SearchBarItem({
  is_type,
  children,
  href,
  active,
  category_image_url,
  selectedFilter,
}) {
  let icon = marker;
  if (href && href.includes('area')) icon = communitiesIcon;
  if (is_type === 'rentals') icon = rentals;
  if (is_type === 'activities') icon = category_image_url;
  if (is_type === 'shopping') icon = category_image_url;
  if (is_type === 'restaurants') icon = category_image_url;
  if (is_type === 'services') icon = category_image_url;
  if (is_type === 'categories') icon = categories;
  if (is_type === 'address') icon = home;
  if (is_type === 'areas') icon = marker;

  return (
    <a
      aria-label="Dropdown Link"
      href={decodeURIComponent(href)}
      onClick={() => {
        // cities and communities both have is_type of cities
        if (is_type === 'cities') {
          window.localStorage.setItem(
            'search_value',
            children.charAt(0).toUpperCase() + children.slice(1)
          );
          window.localStorage.setItem('is_type', selectedFilter);
        } else if (
          is_type === 'categories' ||
          is_type === 'address' ||
          is_type === 'areas'
        ) {
          window.localStorage.setItem('is_type', selectedFilter);
        } else {
          window.localStorage.setItem(
            'is_type',
            is_type === 'rentals' ||
              is_type === 'restaurants' ||
              is_type === 'real_estate'
              ? is_type
              : ['activities', 'shopping', 'services']
          );
        }
      }}
      className={`search-bar-item ${active ? 'active' : ''}`}
    >
      <ReactSVG src={icon} />
      <p>{children}</p>
    </a>
  );
}

SearchBarItem.propTypes = {
  is_type: PropTypes.string,
  children: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  active: PropTypes.bool,
  category_image_url: PropTypes.string,
  selectedFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
};
