/* eslint-disable */
import jQuery from 'jquery';

(function() {
  ($ => {
    /** **************
     * Main Function *
    #***************
     */
    $.fn.priceFormat = function(options) {
      var options;
      let metaKey;
      options = $.extend(true, {}, $.fn.priceFormat.defaults, options);
      window.ctrl_down = false;
      metaKey = false;
      $(window).bind('keyup keydown', e => {
        window.ctrl_down = e.ctrlKey;
        return true;
      });
      $(this).bind('keyup keydown', e => {
        metaKey = e.metaKey;
        return true;
      });
      return this.each(function() {
        let add_prefix;
        let add_suffix;
        let allowNegative;
        let centsLimit;
        let centsSeparator;
        let clearOnEmpty;
        let clearPrefix;
        let clearSuffix;
        let clear_prefix;
        let clear_suffix;
        let fill_with_zeroes;
        let get;
        let insertPlusSign;
        let is_number;
        let key_check;
        let leadingZero;
        let limit;
        let obj;
        let prefix;
        let price_format;
        let price_it;
        let set;
        let suffix;
        let thousandsSeparator;
        let to_numbers;
        let value;
        obj = $(this);
        value = '';
        is_number = /[0-9]/;
        set = function(nvalue) {
          if (obj.is('input')) {
            obj.val(nvalue);
          } else {
            obj.html(nvalue);
          }
          obj.trigger('pricechange');
        };
        get = function() {
          if (obj.is('input')) {
            value = obj.val();
          } else {
            value = obj.html();
          }
          return value;
        };
        to_numbers = function(str) {
          let char_;
          let formatted;
          let i;
          formatted = '';
          i = 0;
          while (i < str.length) {
            char_ = str.charAt(i);
            if (formatted.length == 0 && char_ == 0) {
              char_ = false;
            }
            if (char_ && char_.match(is_number)) {
              if (limit) {
                if (formatted.length < limit) {
                  formatted += char_;
                }
              } else {
                formatted += char_;
              }
            }
            i++;
          }
          return formatted;
        };
        fill_with_zeroes = function(str) {
          while (str.length < centsLimit + 1) {
            str = `0${str}`;
          }
          return str;
        };
        price_format = function(str, ignore) {
          let centsVal;
          let char_;
          let formatted;
          let integerVal;
          let j;
          let thousandsCount;
          let thousandsFormatted;
          if (
            !ignore &&
            (str == '' || str == price_format('0', true)) &&
            clearOnEmpty
          ) {
            return '';
          }
          formatted = fill_with_zeroes(to_numbers(str));
          thousandsFormatted = '';
          thousandsCount = 0;
          if (centsLimit == 0) {
            centsSeparator = '';
            centsVal = '';
          }
          centsVal = formatted.substr(
            formatted.length - centsLimit,
            centsLimit
          );
          integerVal = formatted.substr(0, formatted.length - centsLimit);
          if (leadingZero) {
            formatted = integerVal + centsSeparator + centsVal;
          } else if (integerVal != '0') {
            formatted = integerVal + centsSeparator + centsVal;
          } else {
            formatted = centsSeparator + centsVal;
          }
          if (thousandsSeparator || $.trim(thousandsSeparator) != '') {
            j = integerVal.length;
            while (j > 0) {
              char_ = integerVal.substr(j - 1, 1);
              thousandsCount++;
              if (thousandsCount % 3 == 0) {
                char_ = thousandsSeparator + char_;
              }
              thousandsFormatted = char_ + thousandsFormatted;
              j--;
            }
            if (thousandsFormatted.substr(0, 1) == thousandsSeparator) {
              thousandsFormatted = thousandsFormatted.substring(
                1,
                thousandsFormatted.length
              );
            }
            formatted =
              centsLimit == 0
                ? thousandsFormatted
                : thousandsFormatted + centsSeparator + centsVal;
          }
          if (allowNegative && (integerVal != 0 || centsVal != 0)) {
            if (str.indexOf('-') != -1 && str.indexOf('+') < str.indexOf('-')) {
              formatted = `-${formatted}`;
            } else if (!insertPlusSign) {
              formatted = `${formatted}`;
            } else {
              formatted = `+${formatted}`;
            }
          }
          if (prefix) {
            formatted = prefix + formatted;
          }
          if (suffix) {
            formatted += suffix;
          }
          return formatted;
        };
        key_check = function(e) {
          let code;
          let functional;
          let newValue;
          let str;
          let typed;
          code = e.keyCode ? e.keyCode : e.which;
          typed = String.fromCharCode(code);
          functional = false;
          str = value;
          newValue = price_format(str + typed);
          if ((code >= 48 && code <= 57) || (code >= 96 && code <= 105)) {
            functional = true;
          }
          if (code == 192) {
            functional = true;
          }
          if (code == 8) {
            functional = true;
          }
          if (code == 9) {
            functional = true;
          }
          if (code == 13) {
            functional = true;
          }
          if (code == 46) {
            functional = true;
          }
          if (code == 37) {
            functional = true;
          }
          if (code == 39) {
            functional = true;
          }
          if (allowNegative && (code == 189 || code == 109 || code == 173)) {
            functional = true;
          }
          if (insertPlusSign && (code == 187 || code == 107 || code == 61)) {
            functional = true;
          }
          if (code >= 16 && code <= 20) {
            functional = true;
          }
          if (code == 27) {
            functional = true;
          }
          if (code >= 33 && code <= 40) {
            functional = true;
          }
          if (code >= 44 && code <= 46) {
            functional = true;
          }
          if (window.ctrl_down || metaKey) {
            if (code == 86) {
              functional = true;
            }
            if (code == 67) {
              functional = true;
            }
            if (code == 88) {
              functional = true;
            }
            if (code == 82) {
              functional = true;
            }
            if (code == 84) {
              functional = true;
            }
            if (code == 76) {
              functional = true;
            }
            if (code == 87) {
              functional = true;
            }
            if (code == 81) {
              functional = true;
            }
            if (code == 78) {
              functional = true;
            }
            if (code == 65) {
              functional = true;
            }
          }
          if (!functional) {
            e.preventDefault();
            e.stopPropagation();
            if (str != newValue) {
              set(newValue);
            }
          }
        };
        price_it = function() {
          let format;
          let price;
          let str;
          str = get();
          price = price_format(str);
          if (str != price) {
            set(price);
          }
          format = price_format('0', true);
          if (price == format && str != '0' && clearOnEmpty) {
            set('');
          }
        };
        add_prefix = function() {
          obj.val(prefix + get());
        };
        add_suffix = function() {
          obj.val(get() + suffix);
        };
        clear_prefix = function() {
          let array;
          if ($.trim(prefix) != '' && clearPrefix) {
            array = get().split(prefix);
            set(array[1]);
          }
        };
        clear_suffix = function() {
          let array;
          if ($.trim(suffix) != '' && clearSuffix) {
            array = get().split(suffix);
            set(array[0]);
          }
        };
        if (obj.is('input')) {
          value = obj.val();
        } else {
          value = obj.html();
        }
        prefix = options.prefix;
        suffix = options.suffix;
        centsSeparator = options.centsSeparator;
        thousandsSeparator = options.thousandsSeparator;
        limit = options.limit;
        centsLimit = options.centsLimit;
        clearPrefix = options.clearPrefix;
        clearSuffix = options.clearSuffix;
        allowNegative = options.allowNegative;
        insertPlusSign = options.insertPlusSign;
        clearOnEmpty = options.clearOnEmpty;
        leadingZero = options.leadingZero;
        if (insertPlusSign) {
          allowNegative = true;
        }
        obj.bind('keydown.price_format', key_check);
        obj.bind('keyup.price_format', price_it);
        obj.bind('focusout.price_format', price_it);
        if (clearPrefix) {
          obj.bind('focusout.price_format', () => {
            clear_prefix();
          });
          obj.bind('focusin.price_format', () => {
            add_prefix();
          });
        }
        if (clearSuffix) {
          obj.bind('focusout.price_format', () => {
            clear_suffix();
          });
          obj.bind('focusin.price_format', () => {
            add_suffix();
          });
        }
        if (get().length > 0) {
          price_it();
          clear_prefix();
          clear_suffix();
        }
      });
    };

    /** ********************
     * Remove price format *
    #*********************
     */
    $.fn.unpriceFormat = function() {
      return $(this).unbind('.price_format');
    };

    /** ****************
     * Unmask Function *
    #*****************
     */
    $.fn.unmask = function() {
      let f;
      let field;
      let result;
      field = void 0;
      result = '';
      if ($(this).is('input')) {
        field = $(this).val() || [];
      } else {
        field = $(this).html();
      }
      f = 0;
      while (f < field.length) {
        if (!isNaN(field[f]) || field[f] == '-') {
          result += field[f];
        }
        f++;
      }
      return result;
    };

    /** ****************
     * Price to Float *
    #****************
     */
    $.fn.priceToFloat = function() {
      let field;
      field = void 0;
      if ($(this).is('input')) {
        field = $(this).val() || [];
      } else {
        field = $(this).html();
      }
      return parseFloat(field.replace(/[^0-9\-\.]/g, ''));
    };

    /** **********
     * Defaults *
    #**********
     */
    $.fn.priceFormat.defaults = {
      prefix: 'US$ ',
      suffix: '',
      centsSeparator: '.',
      thousandsSeparator: ',',
      limit: false,
      centsLimit: 2,
      clearPrefix: false,
      clearSufix: false,
      allowNegative: false,
      insertPlusSign: false,
      clearOnEmpty: false,
      leadingZero: true,
    };
  });
}.call(this));
