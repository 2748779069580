import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import ReactSVG from 'react-svg';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import searchIcon from 'search.svg';
import times from 'times.svg';
import { DebounceInput } from 'react-debounce-input';

import cities from 'map_marker.svg';
import rentals from 'building.svg';
import activities from 'running.svg';
import shopping from 'shopping_bag.svg';
import restaurants from 'silverware.svg';
import services from 'food_platter.svg';
import all_else from 'suitcase.svg';
import { SearchContext } from '../context/SearchContext';
import FiltersContainer from './FiltersContainer';
import FilterButton from './FilterButton';

const icons = {
  cities,
  rentals,
  activities,
  shopping,
  restaurants,
  services,
  all_else,
};

function SearchBar() {
  const {
    searchValue,
    setSearchValue,
    selectedFilter,
    setFilter,
    response,
    buildResults,
    resetSearchValue,
    handleSubmit,
    handleInput,
    handleSearch,
    handleFocus,
    setShowDefaults,
    onSearchFocus,
  } = useContext(SearchContext);

  // const NUM_OF_DROPDOWN_ITEMS = 10;

  const [isFocused, setFocused] = useState(false);
  const [formWidth, setFormWidth] = useState(null);

  const searchRef = useRef(null);
  const filterTypeRef = useRef(null);
  const formRef = useRef(null);

  const removeFocus = useCallback(() => {
    setFocused(false);
    clearAllBodyScrollLocks();
    document.querySelector('body').style.overflow = '';
    resetSearchValue();
    // eslint-disable-next-line
    removeListener();
  });

  const handleClickOutside = useCallback(
    e => {
      if (
        searchRef &&
        searchRef.current &&
        !searchRef.current.contains(e.target)
      ) {
        removeFocus();
      }
    },
    [removeFocus]
  );

  const removeListener = useCallback(() => {
    document.removeEventListener('click', handleClickOutside);
  }, [handleClickOutside]);

  const handleActiveItem = direction => {
    const activeDropdownItem = document.querySelector(
      '.search-bar-item.active'
    );
    const lastDropdownItem = document.querySelector('.search-bar-items-wrapper')
      .lastChild;
    const firstDropdownItem = document.querySelector('.search-bar-item');

    if (!activeDropdownItem) {
      // eslint-disable-next-line
      direction === 'next'
        ? firstDropdownItem.classList.add('active')
        : lastDropdownItem.classList.add('active');
    } else if (
      activeDropdownItem && direction === 'next'
        ? activeDropdownItem.nextElementSibling
        : activeDropdownItem.previousElementSibling
    ) {
      // eslint-disable-next-line
      direction === 'next'
        ? activeDropdownItem.nextElementSibling.classList.add('active')
        : activeDropdownItem.previousElementSibling.classList.add('active');
      activeDropdownItem.classList.remove('active');
    } else {
      activeDropdownItem.classList.remove('active');
    }
  };

  // handle selected dropdown item
  useEffect(() => {
    const handleKeyDown = e => {
      if (e.keyCode === 40) {
        // Arrow Down
        handleActiveItem('next');
      }
      if (e.keyCode === 38) {
        // Arrow Up
        handleActiveItem('prev');
      }
      if (e.keyCode === 13) {
        // Enter
        e.preventDefault();
        const selectedItem = document.querySelector('a.active');
        const selectedTab = document.querySelector('.btn:focus');
        // Selected Dropdown Item
        if (selectedItem) selectedItem.click();
        else if (selectedTab) {
          // Selected Tab (Stay, Play, etc...)
          selectedTab.click();
        } else {
          handleSearch();
        }
      }
      if (e.keyCode === 27) {
        // Escape
        removeFocus();
      }
      if (e.keyCode === 9) {
        // Tab
        resetSearchValue();
      }
    };

    if (isFocused) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [
    isFocused,
    removeFocus,
    removeListener,
    response.length,
    resetSearchValue,
    handleSubmit,
    selectedFilter,
    searchValue,
    handleSearch,
  ]);

  // Set form width
  useEffect(() => {
    if (filterTypeRef.current && formRef.current)
      setFormWidth(
        formRef.current.scrollWidth - filterTypeRef.current.scrollWidth
      );
  }, [filterTypeRef, formRef, isFocused]);

  // Handle Focus of dropdown
  useEffect(() => {
    if (isFocused) document.addEventListener('click', handleClickOutside);

    return removeListener;
  }, [handleClickOutside, isFocused, removeListener]);

  const allElseFilter = typeof selectedFilter !== 'string';

  return (
    // eslint-disable-next-line
    <div
      className={`header-search-container ${isFocused ? 'open' : 'close'}`}
      onClick={() => {
        if (!isFocused) setFocused(true);
      }}
      onChange={() => {}}
      ref={searchRef}
    >
      <ReactSVG src={searchIcon} className="header-search-icon" alt="" />
      <form ref={formRef} onSubmit={handleSubmit}>
        <DebounceInput
          aria-label="Search Input"
          style={{
            // eslint-disable-next-line
            width: isFocused
              ? 'calc(100% - 16px)'
              : formWidth
              ? `${formWidth}px`
              : '',
          }}
          className="header-search-input"
          placeholder="Search"
          debounceTimeout={300}
          onChange={e => {
            e.persist();
            handleInput(e);
            handleSubmit(e.target.value, selectedFilter);
          }}
          onBlur={() => resetSearchValue()}
          onFocus={() => {
            handleFocus();
            onSearchFocus();
            setFocused(true);
          }}
          value={searchValue}
        />
      </form>
      {isFocused && (
        <button
          aria-label="Clear Search"
          name="Clear Search"
          className="header-clear-icon"
          type="button"
          onClick={() => {
            setSearchValue('');
            window.localStorage.removeItem('title_cont');
            handleSubmit('');
            setShowDefaults(true);
            removeFocus();
          }}
        >
          <ReactSVG src={times} alt="" />
        </button>
      )}
      {!isFocused && window.location.pathname === '/search' && (
        <section className="header-search-filter-type" ref={filterTypeRef}>
          <ReactSVG src={icons[allElseFilter ? 'all_else' : selectedFilter]} />
          <p>
            In {allElseFilter ? 'All Else' : selectedFilter.replace(/_/g, ' ')}
          </p>
        </section>
      )}
      <FiltersContainer>
        <FilterButton
          className={selectedFilter === 'rentals' ? 'active' : ''}
          onClick={() => {
            setFilter('rentals');
            handleSubmit(searchValue, 'properties');
          }}
        >
          Stay
        </FilterButton>
        <FilterButton
          className={selectedFilter === 'real_estate' ? 'active' : ''}
          onClick={() => {
            setFilter('real_estate');
            handleSubmit(searchValue, 'real_estate');
          }}
        >
          Buy
        </FilterButton>
        <FilterButton
          className={selectedFilter === 'restaurants' ? 'active' : ''}
          onClick={() => {
            setFilter('restaurants');
            handleSubmit(searchValue, 'restaurants');
          }}
        >
          Dine
        </FilterButton>
        <FilterButton
          className={typeof selectedFilter !== 'string' ? 'active' : ''}
          onClick={() => {
            setFilter(['activities', 'shopping', 'services']);
            handleSubmit(searchValue, 'other');
          }}
        >
          All Else
        </FilterButton>
      </FiltersContainer>

      <section className="search-bar-items-wrapper">
        {buildResults(response)}
      </section>
    </div>
  );
}

export default SearchBar;
