// import * as Sentry from '@sentry/browser';
import './price_format';
import './abstract-chosen';
import './chosen-jquery';
import './chosen-proto';
import './select-parser';
import { enableBodyScroll } from 'body-scroll-lock';

$(() => {
  // // ENABLE SENTRY
  // Sentry.init({
  //   dsn:
  //     'https://9f5761c579ad479b8059b9eba6efeb77@o384966.ingest.sentry.io/5223194',
  // });

  // DISABLE TURBOLINKS SCROLL PERSISTANCE
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
  }
  // DISABLE TURBOLINKS SCROLL PERSISTANCE

  // ACCOUNT DROPDOWN
  const headerDropdown = $('#header-account-dropdown');
  const handleClick = () => {
    headerDropdown.addClass('hidden');
  };

  $('#header-account-pfp').click(e => {
    e.stopPropagation();
    if (headerDropdown.hasClass('hidden')) {
      headerDropdown.removeClass('hidden');
      document.addEventListener('click', handleClick);
    } else {
      headerDropdown.addClass('hidden');
      document.removeEventListener('click', handleClick);
    }
  });
  // ACCOUNT DROPDOWN

  // CLEAR SCROLL LOCK
  const { clearAllBodyScrollLocks } = window.bodyScrollLock;

  clearAllBodyScrollLocks();
  $('body').css('overflow', '');
  // CLEAR SCROLL LOCK

  // CLEAR QUERY PARAMS
  setTimeout(() => {
    $('.clear_rental_query_params').click(() => {
      window.sessionStorage.removeItem('rentals_map_query');
    });

    $('.clear_listing_query_params').click(() => {
      window.sessionStorage.removeItem('listings_map_query');
    });
  }, 0);
  // CLEAR QUERY PARAMS

  // FLASH FADE
  const ANIMATION_DURATION = 500;
  const ANIMATION_DELAY = 4000;

  $(() => {
    const alert = $('.alert');
    if (alert.length) {
      setTimeout(
        () =>
          alert.animate(
            { marginTop: -alert[0].offsetHeight },
            ANIMATION_DURATION
          ),
        ANIMATION_DELAY
      );

      setTimeout(
        () => $('.alert > a').click(),
        ANIMATION_DURATION + ANIMATION_DELAY + 1
      );
    }
  });
  // FLASH FADE

  // MOBILE HEADER
  if ($('.header-clear').length) {
    const headerClearMobile = $('.header-clear > .header-nav.header-mobile');

    $(document).scroll(() => {
      if (headerClearMobile && headerClearMobile.offset().top > 100) {
        $('.header-clear').addClass('header-clear-scrolling');
      } else {
        $('.header-clear').removeClass('header-clear-scrolling');
      }
    });
  }

  $('#header-search').on('click', () => {
    $('#search-page').removeClass('hidden');
    $('#search-page').removeClass('disappear');

    $('body').css('overflow', 'hidden');

    $('#close-search-page').on('click', () => {
      $('#search-page').addClass('disappear');

      $('body').css('overflow', '');

      setTimeout(() => {
        $('#search-page').addClass('hidden');
      }, 205);
    });
  });
  // MOBILE HEADER

  // SIDEBAR
  const { disableBodyScroll } = window.bodyScrollLock;

  // Reset on page change

  function disableScroll() {
    disableBodyScroll(document.querySelector('#sidebar'));
  }

  function enableScroll() {
    clearAllBodyScrollLocks();
    $('body').css('overflow', '');
  }

  const toggleSidebar = () => {
    const sidebar = document.querySelector('#sidebar');
    const filterBar = document.querySelector('#filter-section');

    if (sidebar.classList[0] === 'show') {
      enableScroll();
      sidebar.classList = 'hide';
      if (filterBar) {
        filterBar.style.zIndex = '101';
        filterBar.style.transition = 'all 0.5s ease 0s';
      }
    } else if (sidebar.classList[0] === 'hide') {
      disableScroll();
      sidebar.classList = 'show';
      if (filterBar) {
        filterBar.style.zIndex = '-1';
      }
    }
  };

  $('#hamburger-menu').on('click', toggleSidebar);
  $('#close-sidebar').on('click', toggleSidebar);

  // LOGIN MODAL
  const hasVisited = window.sessionStorage.getItem('has_visited');
  if (!hasVisited) {
    setTimeout(() => {
      const signInModal = $('#sign-in-modal');
      const signInExit = $('#sign-in-exit');

      // set session key to determine if user has hit the page before
      window.sessionStorage.setItem('has_visited', true);

      signInModal.removeClass('fadeOut');
      signInModal.removeClass('hidden');
      signInModal.addClass('fadeInSlow');

      signInExit.click(() => {
        signInModal.addClass('fadeOut');
        signInModal.removeClass('fadeIn');
        setTimeout(() => {
          signInModal.addClass('hidden');
        }, 200);
      });
    }, 3000);
  }

  // POPUP AD MODAL
  // if (window.location.pathname.includes('/real-estate')) {
  //   setTimeout(() => {
  //     const popupAdModal = $('#popup-ad');
  //     popupAdModal.removeClass('hidden');
  //     popupAdModal.addClass('fadeInSlow');
  //   }, 20000);
  // } else if (window.location.pathname.includes('/agents')) {
  //   setTimeout(() => {
  //     const popupAdModal = $('#popup-ad');
  //     popupAdModal.removeClass('hidden');
  //     popupAdModal.addClass('fadeInSlow');
  //   }, 20000);
  // }
});
