import React from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import chevron from 'standard-chevron.svg';

export default function DatepickerNext({ direction }) {
  return (
    <span
      className={`btn btn--round custom-calendar-arrow ${
        direction === 'prev' ? 'prev' : 'next'
      }`}
    >
      <ReactSVG src={chevron} />
    </span>
  );
}

DatepickerNext.propTypes = {
  direction: PropTypes.string.isRequired,
};
