import React from 'react';
import ReactSVG from 'react-svg';
import chevron from 'chevron-small.svg';
import { useInView } from 'react-intersection-observer';
import LoadingCard from './Loading/LoadingCard';
import ErrorBoundary from './ErrorBoundary';

function GuideCard({ guide }) {
  const guideImageSplit = guide.medium_background_image_url.split('.');
  const endFormat = guideImageSplit[guideImageSplit.length - 1];
  let imageFormatType = 'jpeg';
  if (endFormat === 'png') imageFormatType = 'png';
  if (endFormat === 'svg') imageFormatType = 'svg+xml';

  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <div ref={ref} className="guide-preview">
      {inView ? (
        <>
          <a
            href={window.Routes.guide_path(guide.slug)}
            className="image-wrapper"
            aria-label={`View ${guide.title} guide`}
          >
            <picture className="background-picture">
              <source
                data-srcset={`${guide.large_background_image_url} 800w, ${guide.medium_background_image_url} 500w, ${guide.small_background_image_url} 300w`}
                sizes="(max-width: 425px) 300px, 500px"
                type={`image/${imageFormatType}`}
              />
              <img
                data-src={guide.medium_background_image_url}
                className="lazyload"
                alt={guide.title}
                title={`${guide.title} Image`}
              />
            </picture>
          </a>
          <a
            href={window.Routes.guide_path(guide.slug)}
            aria-label={`View ${guide.title} guide`}
          >
            <h2 className="guide-title">{guide.title}</h2>
          </a>
          <h3 className="guide-meta-title">{guide.sub_title}</h3>
          <p className="guide-meta-description">
            {guide.meta_description}
            <span />
          </p>
          <a
            href={window.Routes.guide_path(guide.slug)}
            className="guide-link"
            aria-label={`View ${guide.title} guide`}
          >
            <span>Read More </span>
            <ReactSVG src={chevron} />
          </a>
        </>
      ) : (
        <LoadingCard card_type="guide" />
      )}
    </div>
  );
}

export default function GuideCardWrapper({ ...props }) {
  return (
    <ErrorBoundary>
      <GuideCard {...props} />
    </ErrorBoundary>
  );
}
