import React from 'react';

import useWindowSize from '../hooks/useWindowSize';

function AdBlock({ placards, showTwo }) {
  const { width } = useWindowSize();
  const isMobile = width < 768;

  const adCount = () => {
    if (isMobile) {
      return 1;
    }
    if (showTwo) {
      return 2;
    }
    return 4;
  };

  const urlCheck = url => {
    if (!url.includes('https://')) {
      return `https://${url}`;
    }
    return url;
  };

  return (
    <div className="ad-block-container">
      {placards.slice(0, adCount()).map((ad, index) => (
        <div key={index} className="ad-block">
          <img
            src={ad.featured_image_url}
            alt={ad.name}
            className="ad-block-image"
          />
          <p className="ad-block-title">{ad.display_title}</p>
          <p className="ad-block-description">{ad.display_body}</p>
          <a href={urlCheck(ad.url)} target="_blank" rel="noreferrer">
            <button type="button">Learn More</button>
          </a>
        </div>
      ))}
    </div>
  );
}

export default AdBlock;
