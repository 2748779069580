import { gql } from 'apollo-boost';

export const communityPropertiesQuery = gql`
  query COMMUNITY_PROPERTIES_QUERY($comm_id: Int, $premier: Boolean) {
    community_properties(comm_id: $comm_id, premier: $premier) {
      collection {
        id
        comm_id
        name
        beds
        baths
        bookings {
          end_date
          start_date
        }
        city_id
        fees {
          fee_amount
          fee_name
          is_optional
          is_percent
        }
        full_description
        images {
          caption
          order
          url
        }
        sleeps
        property_type
        property_score
        property_owner
        longitude
        latitude
        property_url
        favorited
        ivol_url
        image_url
        amenity_filters
        nightly_rate
        amenities {
          amenity_name
          group_name
        }
        rates {
          nightly_rate
          end_date
          min_nights
          monthly_rate
          season
          start_date
          weekly_rate
          weekend_rate
        }
        rating
        rating_count
      }
      meta {
        total_count
        total_pages
        current_page
        per_page
      }
    }
  }
`;

export const propertiesQuery = gql`
  query PROPERTIES_QUERY(
    $page: String
    $guests: Int
    $prop_type: String
    $prop_name: String
    $check_in: String
    $check_out: String
    $beds: Int
    $baths: Int
    $comm_id: Int
    $city_id: Int
    $max_score: Int
    $min_score: Int
    $max_price: Int
    $min_price: Int
    $randomize: Boolean
    $sort_by: String
    $amenity_list: String
    $polygon: String
  ) {
    properties(
      page: $page
      filter: {
        guests: $guests
        prop_type: $prop_type
        prop_name: $prop_name
        check_in: $check_in
        check_out: $check_out
        beds: $beds
        baths: $baths
        comm_id: $comm_id
        city_id: $city_id
        max_score: $max_score
        min_score: $min_score
        max_price: $max_price
        min_price: $min_price
        randomize: $randomize
        sort_by: $sort_by
        amenity_list: $amenity_list
        polygon: $polygon
      }
    ) {
      collection {
        id
        comm_id
        name
        beds
        baths
        sleeps
        property_type
        property_score
        longitude
        latitude
        property_url
        favorited
        ivol_url
        image_url
        amenity_filters
        nightly_rate
        amenities {
          amenity_name
          group_name
        }
        rates {
          nightly_rate
        }
        rating
        rating_count
      }
      meta {
        total_count
        total_pages
        current_page
        per_page
      }
    }
  }
`;

export const propertyQueryString = `
  query PROPERTY_QUERY($id: Int) {
    property(id: $id) {
      baths
      beds
      favorited
      id
      comm_id
      name
      property_score
      property_type
      property_url
      sleeps
      images {
        url
        caption
      }
      rates {
        nightly_rate
      }
    }
  }
`;

export const propertyQuery = gql`
  ${propertyQueryString}
`;

export const eventsQuery = gql`
  query EVENTS_QUERY($page: String, $listing_city_name_cont_any: [String]) {
    events(
      page: $page
      filter: { listing_city_name_cont_any: $listing_city_name_cont_any }
    ) {
      total_count
      collection {
        id
        title
        start_date
        end_date
        start_time
        end_time
        category
        favorited
        latitude
        longitude
        slug
        all_day_event
        listing {
          city_name
        }
        small_featured_image_url
        medium_featured_image_url
        featured_image {
          id
          image_url
        }
      }
    }
  }
`;

export const listingsQuery = gql`
  query LISTINGS_QUERY(
    $page: String
    $is_type_eq: String
    $city_name_cont: String
    $category_slug_eq: String
    $is_premier_eq: Boolean
    $category_slug_in: [String]
    $title_cont: String
  ) {
    listings(
      page: $page
      filter: {
        is_type_eq: $is_type_eq
        city_name_cont: $city_name_cont
        is_premier_eq: $is_premier_eq
        category_slug_in: $category_slug_in
        category_slug_eq: $category_slug_eq
        title_cont: $title_cont
      }
    ) {
      total_count
      total_pages
      collection {
        id
        title
        category_image_url
        city_name
        city_display_name
        slug
        is_type
        is_premier
        instagram_link
        affiliate_link
        address
        rating
        rating_count
        twitter_link
        website_url
        latitude
        longitude
        favorited
        # featured_image_url
        small_featured_image_url
        medium_featured_image_url
        rating
        rating_count
        special_listing
        images {
          image_url
        }
        category {
          id
          name
          slug
        }
      }
    }
  }
`;

export const gqlSearchQuery = `
  query SEARCH_QUERY($title_cont: String, $search_type: String) {
    search(title_cont: $title_cont, search_type: $search_type) {
      cities {
        name
        video_code
      }
      communities {
        id
        name
        property_count
        latitude
        longitude
        slug
        city_id
      }
      listings {
        id
        title
        slug
        is_type
        city_name
        category {
          name
          slug
        }
        category_image_url
      }
      properties {
        name
        property_url
      }
      categories {
        name
        slug
        is_type
      }
    }
  }
`;

export const gqlListingSearchQuery = `
  query SEARCH_QUERY($title_cont: String) {
    search(title_cont: $title_cont) {
      listings {
        id
        title
        slug
        is_type
        city_name
        category {
          name
        }
        category_image_url
        address
      }
    }
  }
`;

export const searchQuery = gql`
  ${gqlSearchQuery}
`;

export const LISTING_SEARCH_QUERY = gql`
  ${gqlListingSearchQuery}
`;

export const nearbyRentalsQuery = gql`
  query NEARBY_RENTALS_QUERY($polygon: String, $comm_id: Int, $city_id: Int) {
    properties(
      filter: { polygon: $polygon, comm_id: $comm_id, city_id: $city_id }
    ) {
      collection {
        id
        latitude
        longitude
        image_url
        property_type
        property_url
        nightly_rate
        name
        beds
        baths
        sleeps
        rating
        rating_count
      }
    }
  }
`;

export const gqlCommunityQuery = `
  query COMMUNITY_QUERY($id: Int) {
    community(id: $id) {
      name
      latitude
      longitude
    }
  }
`;

export const communityQuery = gql`
  ${gqlCommunityQuery}
`;

export const userQuery = gql`
  query USER_QUERY {
    user {
      id
      first_name
      last_name
      email
    }
  }
`;

export const communitiesQuery = gql`
  query COMMUNITIES_QUERY($city_name: String!, $size: String, $page: String!) {
    communities(city_name: $city_name, size: $size, page: $page) {
      collection {
        id
        name
        featured_image_url
        medium_featured_image_url
        small_featured_image_url
        url
      }
      meta {
        current_page
        per_page
        total_count
        total_pages
      }
    }
  }
`;

export const MAP_LISTINGS_QUERY = gql`
  query MAP_LISTINGS_QUERY(
    $in_polygon: [Float]
    $is_type: String
    $city_name_cont: String
  ) {
    listings(
      page: "1"
      per: "20"
      filter: {
        in_polygon: $in_polygon
        is_type_eq: $is_type
        city_name_cont: $city_name_cont
      }
    ) {
      collection {
        id
        title
        rating
        rating_count
        latitude
        longitude
        is_type
        small_featured_image_url
        category_image_url
        city_name
        slug
        special_listing
        category {
          id
          name
        }
      }
    }
  }
`;

export const LISTINGS_PAGES_QUERY = gql`
  query LISTINGS_PAGES_QUERY($is_type_in: [String]) {
    listings(filter: { is_type_in: $is_type_in }) {
      total_pages
    }
  }
`;

export const RANDOM_LISTINGS_QUERY = gql`
  query RANDOM_LISTINGS_QUERY(
    $per: String
    $page: String
    $is_type_in: [String]
  ) {
    listings(
      page: $page
      per: $per
      filter: { is_type_in: $is_type_in, is_premier_eq: true }
    ) {
      collection {
        id
        title
        slug
        is_type
        city_name
        category_image_url
        special_listing
        category {
          name
        }
      }
    }
  }
`;

export const REAL_ESTATE_TYPE_COUNT = gql`
  query REAL_ESTATE_TYPE_COUNT(
    $property_type_eq: String!
    $sub_type_eq: String!
    $area_slug: String
  ) {
    mls_listings(
      filter: { property_type_eq: $property_type_eq, sub_type_eq: $sub_type_eq }
      area_slug: $area_slug
    ) {
      total_count
    }
  }
`;

export const REAL_ESTATE_LISTINGS_CARD = gql`
  query REAL_ESTATE_LISTINGS_CARD(
    $per: String
    $page: String
    $area_cont: String!
    $listing_status_eq_any: [String]
  ) {
    mls_listings(
      per: $per
      page: $page
      filter: {
        mls_area_cont: $area_cont
        property_type_eq: "A"
        listing_status_eq_any: $listing_status_eq_any
      }
    ) {
      collection {
        id
        feature_image_url
        sub_type
        favorited
        list_price
        address
        total_bedrooms
        total_baths
        apx_sqft
        listing_id
        listing_status
        address_show_url
      }
      total_count
      per_page
      total_pages
      current_page
    }
  }
`;

export const MLS_MAP_LISTINGS = gql`
  query MLS_MAP_LISTINGS(
    $page: String
    # $per: String
    $project_facilities: [String]
    $appliances: [String]
    $waterfront: [String]
    $waterview: [String]
    $fees_include: [String]
    $interior: [String]
    $exterior: [String]
    $lot_features: [String]
    $utilities: [String]
    $rooms: [String]
    $in_polygon: [Float]
    $area_cont: String
    $area_name_eq_any: [String]
    $city_cont: String
    $postal_code_cont: String
    $address_cont: String
    $property_type_eq: String
    $sub_type_eq: String
    $list_price_gteq: Int
    $list_price_lteq: Int
    $total_bedrooms_gteq: Int
    $total_bedrooms_lteq: Int
    $total_baths_gteq: Int
    $total_baths_lteq: Int
    $apx_sqft_gteq: Int
    $apx_sqft_lteq: Int
    $sub_type_eq_any: [String]
    $listing_status_eq_any: [String]
    $year_built_gteq: Int
    $year_built_lteq: Int
    $acreage_gteq: Float
    $acreage_lteq: Float
    $days_on_market_lteq: Int
    $sale_type: [String]
    $unbranded_virtual_tour_present: Int
    $price_sort: String
    $age_sort: String
    $num_stories_lteq: Float
    $park_features: [String]
    $price_reduced: Boolean
  ) {
    mls_listings(
      page: $page
      # per: $per
      per: "18"
      project_facilities: $project_facilities
      appliances: $appliances
      waterfront: $waterfront
      waterview: $waterview
      fees_include: $fees_include
      interior: $interior
      exterior: $exterior
      lot_features: $lot_features
      utilities: $utilities
      sale_type: $sale_type
      rooms: $rooms
      price_sort: $price_sort
      age_sort: $age_sort
      park_features: $park_features
      price_reduced: $price_reduced
      filter: {
        in_polygon: $in_polygon
        mls_area_cont: $area_cont
        area_name_eq_any: $area_name_eq_any
        city_cont: $city_cont
        postal_code_cont: $postal_code_cont
        address_cont: $address_cont
        property_type_eq: $property_type_eq
        sub_type_eq: $sub_type_eq
        list_price_gteq: $list_price_gteq
        list_price_lteq: $list_price_lteq
        total_bedrooms_gteq: $total_bedrooms_gteq
        total_bedrooms_lteq: $total_bedrooms_lteq
        total_baths_gteq: $total_baths_gteq
        total_baths_lteq: $total_baths_lteq
        apx_sqft_gteq: $apx_sqft_gteq
        apx_sqft_lteq: $apx_sqft_lteq
        sub_type_eq_any: $sub_type_eq_any
        listing_status_eq_any: $listing_status_eq_any
        year_built_gteq: $year_built_gteq
        year_built_lteq: $year_built_lteq
        acreage_gteq: $acreage_gteq
        acreage_lteq: $acreage_lteq
        days_on_market_lteq: $days_on_market_lteq
        unbranded_virtual_tour_present: $unbranded_virtual_tour_present
        num_stories_lteq: $num_stories_lteq
      }
    ) {
      collection {
        id
        mls_area
        feature_image_url
        sub_type
        favorited
        list_price
        sold_price
        total_bedrooms
        total_baths
        apx_sqft
        address
        listing_id
        latitude
        longitude
        listing_status
        acreage
        property_type
        address_show_url
      }
      total_count
      per_page
      total_pages
      current_page
    }
  }
`;

export const MLS_LISTING_MARKERS = gql`
  query MLS_LISTING_MARKERS(
    $page: String
    # $per: String
    $project_facilities: [String]
    $appliances: [String]
    $waterfront: [String]
    $waterview: [String]
    $fees_include: [String]
    $interior: [String]
    $exterior: [String]
    $lot_features: [String]
    $utilities: [String]
    $rooms: [String]
    $in_polygon: [Float]
    $area_cont: String
    $area_name_eq_any: [String]
    $city_cont: String
    $postal_code_cont: String
    $address_cont: String
    $property_type_eq: String
    $sub_type_eq: String
    $list_price_gteq: Int
    $list_price_lteq: Int
    $total_bedrooms_gteq: Int
    $total_bedrooms_lteq: Int
    $total_baths_gteq: Int
    $total_baths_lteq: Int
    $apx_sqft_gteq: Int
    $apx_sqft_lteq: Int
    $sub_type_eq_any: [String]
    $listing_status_eq_any: [String]
    $year_built_gteq: Int
    $year_built_lteq: Int
    $acreage_gteq: Float
    $acreage_lteq: Float
    $days_on_market_lteq: Int
    $sale_type: [String]
    $unbranded_virtual_tour_present: Int
    $price_sort: String
    $age_sort: String
    $num_stories_lteq: Float
    $park_features: [String]
    $price_reduced: Boolean
  ) {
    mls_listings(
      page: $page
      per: "10000"
      project_facilities: $project_facilities
      appliances: $appliances
      waterfront: $waterfront
      waterview: $waterview
      fees_include: $fees_include
      interior: $interior
      exterior: $exterior
      lot_features: $lot_features
      utilities: $utilities
      sale_type: $sale_type
      rooms: $rooms
      price_sort: $price_sort
      age_sort: $age_sort
      park_features: $park_features
      price_reduced: $price_reduced
      filter: {
        in_polygon: $in_polygon
        mls_area_cont: $area_cont
        area_name_eq_any: $area_name_eq_any
        city_cont: $city_cont
        postal_code_cont: $postal_code_cont
        address_cont: $address_cont
        property_type_eq: $property_type_eq
        sub_type_eq: $sub_type_eq
        list_price_gteq: $list_price_gteq
        list_price_lteq: $list_price_lteq
        total_bedrooms_gteq: $total_bedrooms_gteq
        total_bedrooms_lteq: $total_bedrooms_lteq
        total_baths_gteq: $total_baths_gteq
        total_baths_lteq: $total_baths_lteq
        apx_sqft_gteq: $apx_sqft_gteq
        apx_sqft_lteq: $apx_sqft_lteq
        sub_type_eq_any: $sub_type_eq_any
        listing_status_eq_any: $listing_status_eq_any
        year_built_gteq: $year_built_gteq
        year_built_lteq: $year_built_lteq
        acreage_gteq: $acreage_gteq
        acreage_lteq: $acreage_lteq
        days_on_market_lteq: $days_on_market_lteq
        unbranded_virtual_tour_present: $unbranded_virtual_tour_present
        num_stories_lteq: $num_stories_lteq
      }
    ) {
      collection {
        id
        feature_image_url
        sub_type
        list_price
        sold_price
        total_bedrooms
        total_baths
        apx_sqft
        listing_id
        listing_status
        latitude
        longitude
        acreage
        property_type
        address_show_url
      }
      total_count
      per_page
      total_pages
      current_page
    }
  }
`;

export const REAL_ESTATE_SEARCH_QUERY = `
  query REAL_ESTATE_SEARCH($title_cont: String) {
    mls_search(title_cont: $title_cont) {
      areas {
        id
        name
        slug
      }
      mls_listings {
        id
        address
        address_show_url
      }
      zipcodes {
        postal_code
        city
        state
      }
    }
  }
`;

export const REAL_ESTATE_SEARCH = gql`
  ${REAL_ESTATE_SEARCH_QUERY}
`;

export const RANDOM_REAL_ESTATE_SEARCH = gql`
  query RANDOM_REAL_ESTATE_SEARCH($random: Boolean, $per: String) {
    mls_listings(random: $random, per: $per) {
      collection {
        id
        address
        address_show_url
      }
    }
  }
`;

export const GET_MLS_LISTING = gql`
  query GET_MLS_LISTING($id: ID) {
    mls_listing(id: $id) {
      acreage
      address
      address_show_url
      agency_relationship
      agent {
        id
        first_name
        last_name
        mls_agent_id
        designation
        broker_company {
          id
          logo_url
          name
        }
        profile_photo_url
      }
      agent_id
      agent_to_agent_remarks
      appliances
      apx_sqft
      area {
        id
        name
        slug
      }
      as_is
      assc_mgmt_company
      assc_mgmt_contact
      assessment_amount
      assessment_fee
      assessment_paid
      assigned_spaces
      assumable_mtg_typ
      auction_list_num
      balcony_sqft
      beach_frontage
      below_ground_sqft
      bonus
      brokerage_interest
      buyer_name
      buyer_name_withheld
      cancel_date
      carport
      carport_spaces
      city
      co_list_agent_id
      co_list_office_id
      co_listing_member_name
      co_listing_member_shortid
      co_listing_office_name
      co_listing_office_shortid
      co_selling_agency_id
      co_selling_member_name
      co_selling_member_shortid
      co_selling_office_name
      co_selling_office_shortid
      comp_see_notes
      compensation_notes
      compensation_reduction_dscl
      construction_siding
      construction_status
      contingent
      county
      covered_spaces
      cumulative_dom
      days_on_market
      description
      design
      directions
      document_count
      document_timestamp
      driveway_spaces
      dual_variable_rate
      elementary_school
      energy
      expiration_date
      exterior
      farm_info
      favorited
      feature_image_url
      fees_include
      full_baths
      garage
      garage_spaces
      half_baths
      high_school
      homestead
      hot_sheet_comments
      id
      images
      immediate_occupancy
      interior
      internal_listing_id
      is_subject_leased
      land_lease_per_year
      last_tax_year
      last_taxes
      latitude
      lease_expiration_date
      legal_description
      limited_service_list
      list_agent_board_code
      list_agent_board_id
      list_date
      list_office_board_code
      list_office_board_id
      list_price
      list_price_per_sqft
      list_type
      listing_board
      listing_id
      listing_member_name
      listing_member_shortid
      listing_num_main
      listing_num_prefix
      listing_office_name
      listing_office_shortid
      listing_status
      longitude
      lot_dimensions
      lot_features
      lot_parcel_access
      middle_school
      mls_approved
      mls_area
      mls_identifier
      mls_sub_area
      modification_timestamp
      mortgage_type
      mstr_bdrm_mstr_bath
      named_exception
      new_buyer_fee
      new_buyer_fee_amount
      new_buyer_fee_paid
      non_rep_comp
      num_stories
      occupancy_status
      office_file_num
      original_list_price
      parking_features
      picture_count
      pool
      pool_type
      postal_code
      pre_const_flip
      previous_price
      project_facilities
      projected_close_date
      property_group_id
      property_type
      rent_per_month
      rooms
      sale_notes
      sale_type
      searchable_address
      seller_name_withheld
      seller_nm
      seller_num
      seller_phone_num
      selling_agency_id
      selling_agent_id
      selling_member_name
      selling_member_shortid
      selling_office_name
      selling_office_shortid
      showing
      single_agent_comp
      sold_date
      sold_price
      sold_price_per_sqft
      sqft_source
      state
      status_change_date
      stories_in_building
      stories_in_unit
      street_direction
      street_name
      street_num
      street_spaces
      street_suffix
      sub_area {
        id
        median_list_price
        median_price_per_sqft
        name
        slug
      }
      sub_type
      subdivision
      tax_id
      terms_of_bonus
      total_baths
      total_bedrooms
      trans_broker_comp
      updated_at
      unbranded_virtual_tour
      under_contract_date
      unit_num
      units_above_ground
      units_in_building
      utilities
      vow_address_display
      vow_automated_valuation_display
      vow_consumer_comment
      vow_entire_listing_display
      waterfront
      waterfront_feet
      waterview
      withdrawal_date
      year_built
      zoning
    }
  }
`;

export const QUOTES = gql`
  query QUOTES($propertyId: Int!, $checkIn: String!, $checkOut: String!) {
    quote(property_id: $propertyId, check_in: $checkIn, check_out: $checkOut) {
      errors
      result
    }
  }
`;
