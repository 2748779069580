import React from 'react';

function AlertBanner({ ...props }) {
  try {
    const { localStorage } = window;
    return null;
  } catch (err) {
    return (
      <aside className="alert-banner" {...props}>
        <p>
          <strong>Please enable site data.</strong> In order to give you the
          best experience on DestinFlorida.com, we ask that you turn on site
          data (i.e. cookies). A large portion of DestinFlorida.com relies on
          site data in order to work properly. Please visit the link and follow
          the guide to enable site data.
        </p>
        <a
          href="https://www.whatismybrowser.com/guides/how-to-enable-cookies/"
          target="_blank"
          rel="noopener noreferrer"
          name="Enable Site Data"
          aria-label="Enable Site Data"
          title="Enable Site Data"
          className="btn btn--blue-grad"
        >
          Enable Site Data
        </a>
      </aside>
    );
  }
}

export default AlertBanner;
