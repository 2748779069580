import React from 'react';
import PropTypes from 'prop-types';

export default function LoadingCard({ card_type, is_small }) {
  if (card_type === 'events') {
    return (
      <div className="loading-rental events">
        <section>
          <div className="loading-text small" />
          <div className="loading-text full" />
          <div className="loading-text med" />
        </section>
      </div>
    );
  }

  if (card_type === 'instagram') {
    return (
      <div className="loading-rental instagram">
        <section />
      </div>
    );
  }

  if (card_type === 'community') {
    return <div className="loading-rental community" />;
  }

  if (card_type === 'guide') {
    return (
      <div className="loading-rental">
        <section className="loading-image-outer">
          <div />
        </section>
        <section>
          <div className="loading-text med" />
          <div className="loading-text full" />
          <div className="loading-text full" />
          <div className="loading-text small" />
          <div className="loading-text xs" />
        </section>
      </div>
    );
  }

  return (
    <div className={`loading-rental ${is_small ? 'is_small' : ''}`}>
      <section className="loading-image-outer">
        <div />
      </section>
      <section>
        <div className="loading-text xs" />
        <div className="loading-text full" />
        <div className="loading-text med" />
        <div className="loading-text small" />
      </section>
    </div>
  );
}

LoadingCard.propTypes = {
  card_type: PropTypes.string,
  is_small: PropTypes.bool,
};
