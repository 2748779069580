/* eslint-disable */
(function() {
  let $;
  let Chosen;
  const __extends = function(child, parent) {
    for (const key in parent) {
      if (__hasProp.call(parent, key)) child[key] = parent[key];
    }
    function ctor() {
      this.constructor = child;
    }
    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  };
  var __hasProp = {}.hasOwnProperty;

  $ = jQuery;

  $.fn.extend({
    chosen(options) {
      return this.each(function(input_field) {
        let $this;
        let chosen;
        $this = $(this);
        chosen = $this.data('chosen');
        if (options === 'destroy') {
          if (chosen instanceof Chosen) {
            chosen.destroy();
          }
          return;
        }
        if (!(chosen instanceof Chosen)) {
          $this.data('chosen', new Chosen(this, options));
        }
      });
    },
  });

  Chosen = (function(_super) {
    __extends(Chosen, _super);

    function Chosen() {
      return Chosen.__super__.constructor.apply(this, arguments);
    }

    Chosen.prototype.setup = function() {
      this.form_field_jq = $(this.form_field);
      return (this.current_selectedIndex = this.form_field.selectedIndex);
    };

    Chosen.prototype.set_up_html = function() {
      let container_classes;
      let container_props;
      container_classes = ['chosen-container'];
      container_classes.push(
        `chosen-container-${this.is_multiple ? 'multi' : 'single'}`
      );
      if (this.inherit_select_classes && this.form_field.className) {
        container_classes.push(this.form_field.className);
      }
      if (this.is_rtl) {
        container_classes.push('chosen-rtl');
      }
      container_props = {
        class: container_classes.join(' '),
        title: this.form_field.title,
      };
      if (this.form_field.id.length) {
        container_props.id = `${this.form_field.id.replace(
          /[^\w]/g,
          '_'
        )}_chosen`;
      }
      this.container = $('<div />', container_props);
      this.container.width(this.container_width());
      if (this.is_multiple) {
        this.container.html(this.get_multi_html());
      } else {
        this.container.html(this.get_single_html());
      }
      this.form_field_jq.hide().after(this.container);
      this.dropdown = this.container.find('div.chosen-drop').first();
      this.search_field = this.container.find('input').first();
      this.search_results = this.container.find('ul.chosen-results').first();
      this.search_field_scale();
      this.search_no_results = this.container.find('li.no-results').first();
      if (this.is_multiple) {
        this.search_choices = this.container.find('ul.chosen-choices').first();
        this.search_container = this.container.find('li.search-field').first();
      } else {
        this.search_container = this.container
          .find('div.chosen-search')
          .first();
        this.selected_item = this.container.find('.chosen-single').first();
      }
      this.results_build();
      this.set_tab_index();
      return this.set_label_behavior();
    };

    Chosen.prototype.on_ready = function() {
      return this.form_field_jq.trigger('chosen:ready', {
        chosen: this,
      });
    };

    Chosen.prototype.register_observers = function() {
      this.container.on(
        'touchstart.chosen',
        (function(_this) {
          return function(evt) {
            _this.container_mousedown(evt);
          };
        })(this)
      );
      this.container.on(
        'touchend.chosen',
        (function(_this) {
          return function(evt) {
            _this.container_mouseup(evt);
          };
        })(this)
      );
      this.container.on(
        'mousedown.chosen',
        (function(_this) {
          return function(evt) {
            _this.container_mousedown(evt);
          };
        })(this)
      );
      this.container.on(
        'mouseup.chosen',
        (function(_this) {
          return function(evt) {
            _this.container_mouseup(evt);
          };
        })(this)
      );
      this.container.on(
        'mouseenter.chosen',
        (function(_this) {
          return function(evt) {
            _this.mouse_enter(evt);
          };
        })(this)
      );
      this.container.on(
        'mouseleave.chosen',
        (function(_this) {
          return function(evt) {
            _this.mouse_leave(evt);
          };
        })(this)
      );
      this.search_results.on(
        'mouseup.chosen',
        (function(_this) {
          return function(evt) {
            _this.search_results_mouseup(evt);
          };
        })(this)
      );
      this.search_results.on(
        'mouseover.chosen',
        (function(_this) {
          return function(evt) {
            _this.search_results_mouseover(evt);
          };
        })(this)
      );
      this.search_results.on(
        'mouseout.chosen',
        (function(_this) {
          return function(evt) {
            _this.search_results_mouseout(evt);
          };
        })(this)
      );
      this.search_results.on(
        'mousewheel.chosen DOMMouseScroll.chosen',
        (function(_this) {
          return function(evt) {
            _this.search_results_mousewheel(evt);
          };
        })(this)
      );
      this.search_results.on(
        'touchstart.chosen',
        (function(_this) {
          return function(evt) {
            _this.search_results_touchstart(evt);
          };
        })(this)
      );
      this.search_results.on(
        'touchmove.chosen',
        (function(_this) {
          return function(evt) {
            _this.search_results_touchmove(evt);
          };
        })(this)
      );
      this.search_results.on(
        'touchend.chosen',
        (function(_this) {
          return function(evt) {
            _this.search_results_touchend(evt);
          };
        })(this)
      );
      this.form_field_jq.on(
        'chosen:updated.chosen',
        (function(_this) {
          return function(evt) {
            _this.results_update_field(evt);
          };
        })(this)
      );
      this.form_field_jq.on(
        'chosen:activate.chosen',
        (function(_this) {
          return function(evt) {
            _this.activate_field(evt);
          };
        })(this)
      );
      this.form_field_jq.on(
        'chosen:open.chosen',
        (function(_this) {
          return function(evt) {
            _this.container_mousedown(evt);
          };
        })(this)
      );
      this.form_field_jq.on(
        'chosen:close.chosen',
        (function(_this) {
          return function(evt) {
            _this.close_field(evt);
          };
        })(this)
      );
      this.search_field.on(
        'blur.chosen',
        (function(_this) {
          return function(evt) {
            _this.input_blur(evt);
          };
        })(this)
      );
      this.search_field.on(
        'keyup.chosen',
        (function(_this) {
          return function(evt) {
            _this.keyup_checker(evt);
          };
        })(this)
      );
      this.search_field.on(
        'keydown.chosen',
        (function(_this) {
          return function(evt) {
            _this.keydown_checker(evt);
          };
        })(this)
      );
      this.search_field.on(
        'focus.chosen',
        (function(_this) {
          return function(evt) {
            _this.input_focus(evt);
          };
        })(this)
      );
      this.search_field.on(
        'cut.chosen',
        (function(_this) {
          return function(evt) {
            _this.clipboard_event_checker(evt);
          };
        })(this)
      );
      this.search_field.on(
        'paste.chosen',
        (function(_this) {
          return function(evt) {
            _this.clipboard_event_checker(evt);
          };
        })(this)
      );
      if (this.is_multiple) {
        return this.search_choices.on(
          'click.chosen',
          (function(_this) {
            return function(evt) {
              _this.choices_click(evt);
            };
          })(this)
        );
      }
      return this.container.on('click.chosen', evt => {
        evt.preventDefault();
      });
    };

    Chosen.prototype.destroy = function() {
      $(this.container[0].ownerDocument).off(
        'click.chosen',
        this.click_test_action
      );
      if (this.form_field_label.length > 0) {
        this.form_field_label.off('click.chosen');
      }
      if (this.search_field[0].tabIndex) {
        this.form_field_jq[0].tabIndex = this.search_field[0].tabIndex;
      }
      this.container.remove();
      this.form_field_jq.removeData('chosen');
      return this.form_field_jq.show();
    };

    Chosen.prototype.search_field_disabled = function() {
      this.is_disabled =
        this.form_field.disabled ||
        this.form_field_jq.parents('fieldset').is(':disabled');
      this.container.toggleClass('chosen-disabled', this.is_disabled);
      this.search_field[0].disabled = this.is_disabled;
      if (!this.is_multiple) {
        this.selected_item.off('focus.chosen', this.activate_field);
      }
      if (this.is_disabled) {
        return this.close_field();
      }
      if (!this.is_multiple) {
        return this.selected_item.on('focus.chosen', this.activate_field);
      }
    };

    Chosen.prototype.container_mousedown = function(evt) {
      let _ref;
      if (this.is_disabled) {
        return;
      }
      if (
        evt &&
        ((_ref = evt.type) === 'mousedown' || _ref === 'touchstart') &&
        !this.results_showing
      ) {
        evt.preventDefault();
      }
      if (!(evt != null && $(evt.target).hasClass('search-choice-close'))) {
        if (!this.active_field) {
          if (this.is_multiple) {
            this.search_field.val('');
          }
          $(this.container[0].ownerDocument).on(
            'click.chosen',
            this.click_test_action
          );
          this.results_show();
        } else if (
          !this.is_multiple &&
          evt &&
          ($(evt.target)[0] === this.selected_item[0] ||
            $(evt.target).parents('a.chosen-single').length)
        ) {
          evt.preventDefault();
          this.results_toggle();
        }
        return this.activate_field();
      }
    };

    Chosen.prototype.container_mouseup = function(evt) {
      if (evt.target.nodeName === 'ABBR' && !this.is_disabled) {
        return this.results_reset(evt);
      }
    };

    Chosen.prototype.search_results_mousewheel = function(evt) {
      let delta;
      if (evt.originalEvent) {
        delta =
          evt.originalEvent.deltaY ||
          -evt.originalEvent.wheelDelta ||
          evt.originalEvent.detail;
      }
      if (delta != null) {
        evt.preventDefault();
        if (evt.type === 'DOMMouseScroll') {
          delta *= 40;
        }
        return this.search_results.scrollTop(
          delta + this.search_results.scrollTop()
        );
      }
    };

    Chosen.prototype.blur_test = function(evt) {
      if (
        !this.active_field &&
        this.container.hasClass('chosen-container-active')
      ) {
        return this.close_field();
      }
    };

    Chosen.prototype.close_field = function() {
      $(this.container[0].ownerDocument).off(
        'click.chosen',
        this.click_test_action
      );
      this.active_field = false;
      this.results_hide();
      this.container.removeClass('chosen-container-active');
      this.clear_backstroke();
      this.show_search_field_default();
      this.search_field_scale();
      return this.search_field.blur();
    };

    Chosen.prototype.activate_field = function() {
      if (this.is_disabled) {
        return;
      }
      this.container.addClass('chosen-container-active');
      this.active_field = true;
      this.search_field.val(this.search_field.val());
      return this.search_field.focus();
    };

    Chosen.prototype.test_active_click = function(evt) {
      let active_container;
      active_container = $(evt.target).closest('.chosen-container');
      if (
        active_container.length &&
        this.container[0] === active_container[0]
      ) {
        return (this.active_field = true);
      }
      return this.close_field();
    };

    Chosen.prototype.results_build = function() {
      this.parsing = true;
      this.selected_option_count = null;
      this.results_data = SelectParser.select_to_array(this.form_field);
      if (this.is_multiple) {
        this.search_choices.find('li.search-choice').remove();
      } else {
        this.single_set_selected_text();
        if (
          this.disable_search ||
          this.form_field.options.length <= this.disable_search_threshold
        ) {
          this.search_field[0].readOnly = true;
          this.container.addClass('chosen-container-single-nosearch');
        } else {
          this.search_field[0].readOnly = false;
          this.container.removeClass('chosen-container-single-nosearch');
        }
      }
      this.update_results_content(
        this.results_option_build({
          first: true,
        })
      );
      this.search_field_disabled();
      this.show_search_field_default();
      this.search_field_scale();
      return (this.parsing = false);
    };

    Chosen.prototype.result_do_highlight = function(el) {
      let high_bottom;
      let high_top;
      let maxHeight;
      let visible_bottom;
      let visible_top;
      if (el.length) {
        this.result_clear_highlight();
        this.result_highlight = el;
        this.result_highlight.addClass('highlighted');
        maxHeight = parseInt(this.search_results.css('maxHeight'), 10);
        visible_top = this.search_results.scrollTop();
        visible_bottom = maxHeight + visible_top;
        high_top =
          this.result_highlight.position().top +
          this.search_results.scrollTop();
        high_bottom = high_top + this.result_highlight.outerHeight();
        if (high_bottom >= visible_bottom) {
          return this.search_results.scrollTop(
            high_bottom - maxHeight > 0 ? high_bottom - maxHeight : 0
          );
        }
        if (high_top < visible_top) {
          return this.search_results.scrollTop(high_top);
        }
      }
    };

    Chosen.prototype.result_clear_highlight = function() {
      if (this.result_highlight) {
        this.result_highlight.removeClass('highlighted');
      }
      return (this.result_highlight = null);
    };

    Chosen.prototype.results_show = function() {
      if (
        this.is_multiple &&
        this.max_selected_options <= this.choices_count()
      ) {
        this.form_field_jq.trigger('chosen:maxselected', {
          chosen: this,
        });
        return false;
      }
      this.container.addClass('chosen-with-drop');
      this.results_showing = true;
      this.search_field.focus();
      this.search_field.val(this.get_search_field_value());
      this.winnow_results();
      return this.form_field_jq.trigger('chosen:showing_dropdown', {
        chosen: this,
      });
    };

    Chosen.prototype.update_results_content = function(content) {
      return this.search_results.html(content);
    };

    Chosen.prototype.results_hide = function() {
      if (this.results_showing) {
        this.result_clear_highlight();
        this.container.removeClass('chosen-with-drop');
        this.form_field_jq.trigger('chosen:hiding_dropdown', {
          chosen: this,
        });
      }
      return (this.results_showing = false);
    };

    Chosen.prototype.set_tab_index = function(el) {
      let ti;
      if (this.form_field.tabIndex) {
        ti = this.form_field.tabIndex;
        this.form_field.tabIndex = -1;
        return (this.search_field[0].tabIndex = ti);
      }
    };

    Chosen.prototype.set_label_behavior = function() {
      this.form_field_label = this.form_field_jq.parents('label');
      if (!this.form_field_label.length && this.form_field.id.length) {
        this.form_field_label = $(`label[for='${this.form_field.id}']`);
      }
      if (this.form_field_label.length > 0) {
        return this.form_field_label.on(
          'click.chosen',
          this.label_click_handler
        );
      }
    };

    Chosen.prototype.show_search_field_default = function() {
      if (this.is_multiple && this.choices_count() < 1 && !this.active_field) {
        this.search_field.val(this.default_text);
        return this.search_field.addClass('default');
      }
      this.search_field.val('');
      return this.search_field.removeClass('default');
    };

    Chosen.prototype.search_results_mouseup = function(evt) {
      let target;
      target = $(evt.target).hasClass('active-result')
        ? $(evt.target)
        : $(evt.target)
            .parents('.active-result')
            .first();
      if (target.length) {
        this.result_highlight = target;
        this.result_select(evt);
        return this.search_field.focus();
      }
    };

    Chosen.prototype.search_results_mouseover = function(evt) {
      let target;
      target = $(evt.target).hasClass('active-result')
        ? $(evt.target)
        : $(evt.target)
            .parents('.active-result')
            .first();
      if (target) {
        return this.result_do_highlight(target);
      }
    };

    Chosen.prototype.search_results_mouseout = function(evt) {
      if (
        $(evt.target).hasClass('active-result') ||
        $(evt.target)
          .parents('.active-result')
          .first()
      ) {
        return this.result_clear_highlight();
      }
    };

    Chosen.prototype.choice_build = function(item) {
      let choice;
      let close_link;
      choice = $('<li />', {
        class: 'search-choice',
      }).html(`<span>${this.choice_label(item)}</span>`);
      if (item.disabled) {
        choice.addClass('search-choice-disabled');
      } else {
        close_link = $('<a />', {
          class: 'search-choice-close',
          'data-option-array-index': item.array_index,
        });
        close_link.on(
          'click.chosen',
          (function(_this) {
            return function(evt) {
              return _this.choice_destroy_link_click(evt);
            };
          })(this)
        );
        choice.append(close_link);
      }
      return this.search_container.before(choice);
    };

    Chosen.prototype.choice_destroy_link_click = function(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      if (!this.is_disabled) {
        return this.choice_destroy($(evt.target));
      }
    };

    Chosen.prototype.choice_destroy = function(link) {
      if (
        this.result_deselect(link[0].getAttribute('data-option-array-index'))
      ) {
        if (this.active_field) {
          this.search_field.focus();
        } else {
          this.show_search_field_default();
        }
        if (
          this.is_multiple &&
          this.choices_count() > 0 &&
          this.get_search_field_value().length < 1
        ) {
          this.results_hide();
        }
        link
          .parents('li')
          .first()
          .remove();
        return this.search_field_scale();
      }
    };

    Chosen.prototype.results_reset = function() {
      this.reset_single_select_options();
      this.form_field.options[0].selected = true;
      this.single_set_selected_text();
      this.show_search_field_default();
      this.results_reset_cleanup();
      this.trigger_form_field_change();
      if (this.active_field) {
        return this.results_hide();
      }
    };

    Chosen.prototype.results_reset_cleanup = function() {
      this.current_selectedIndex = this.form_field.selectedIndex;
      return this.selected_item.find('abbr').remove();
    };

    Chosen.prototype.result_select = function(evt) {
      let high;
      let item;
      if (this.result_highlight) {
        high = this.result_highlight;
        this.result_clear_highlight();
        if (
          this.is_multiple &&
          this.max_selected_options <= this.choices_count()
        ) {
          this.form_field_jq.trigger('chosen:maxselected', {
            chosen: this,
          });
          return false;
        }
        if (this.is_multiple) {
          high.removeClass('active-result');
        } else {
          this.reset_single_select_options();
        }
        high.addClass('result-selected');
        item = this.results_data[
          high[0].getAttribute('data-option-array-index')
        ];
        item.selected = true;
        this.form_field.options[item.options_index].selected = true;
        this.selected_option_count = null;
        if (this.is_multiple) {
          this.choice_build(item);
        } else {
          this.single_set_selected_text(this.choice_label(item));
        }
        if (
          this.is_multiple &&
          (!this.hide_results_on_select || (evt.metaKey || evt.ctrlKey))
        ) {
          if (evt.metaKey || evt.ctrlKey) {
            this.winnow_results({
              skip_highlight: true,
            });
          } else {
            this.search_field.val('');
            this.winnow_results();
          }
        } else {
          this.results_hide();
          this.show_search_field_default();
        }
        if (
          this.is_multiple ||
          this.form_field.selectedIndex !== this.current_selectedIndex
        ) {
          this.trigger_form_field_change({
            selected: this.form_field.options[item.options_index].value,
          });
        }
        this.current_selectedIndex = this.form_field.selectedIndex;
        evt.preventDefault();
        return this.search_field_scale();
      }
    };

    Chosen.prototype.single_set_selected_text = function(text) {
      if (text == null) {
        text = this.default_text;
      }
      if (text === this.default_text) {
        this.selected_item.addClass('chosen-default');
      } else {
        this.single_deselect_control_build();
        this.selected_item.removeClass('chosen-default');
      }
      return this.selected_item.find('span').html(text);
    };

    Chosen.prototype.result_deselect = function(pos) {
      let result_data;
      result_data = this.results_data[pos];
      if (!this.form_field.options[result_data.options_index].disabled) {
        result_data.selected = false;
        this.form_field.options[result_data.options_index].selected = false;
        this.selected_option_count = null;
        this.result_clear_highlight();
        if (this.results_showing) {
          this.winnow_results();
        }
        this.trigger_form_field_change({
          deselected: this.form_field.options[result_data.options_index].value,
        });
        this.search_field_scale();
        return true;
      }
      return false;
    };

    Chosen.prototype.single_deselect_control_build = function() {
      if (!this.allow_single_deselect) {
        return;
      }
      if (!this.selected_item.find('abbr').length) {
        this.selected_item
          .find('span')
          .first()
          .after('<abbr class="search-choice-close"></abbr>');
      }
      return this.selected_item.addClass('chosen-single-with-deselect');
    };

    Chosen.prototype.get_search_field_value = function() {
      return this.search_field.val();
    };

    Chosen.prototype.get_search_text = function() {
      return $.trim(this.get_search_field_value());
    };

    Chosen.prototype.escape_html = function(text) {
      return $('<div/>')
        .text(text)
        .html();
    };

    Chosen.prototype.winnow_results_set_highlight = function() {
      let do_high;
      let selected_results;
      selected_results = !this.is_multiple
        ? this.search_results.find('.result-selected.active-result')
        : [];
      do_high = selected_results.length
        ? selected_results.first()
        : this.search_results.find('.active-result').first();
      if (do_high != null) {
        return this.result_do_highlight(do_high);
      }
    };

    Chosen.prototype.no_results = function(terms) {
      let no_results_html;
      no_results_html = this.get_no_results_html(terms);
      this.search_results.append(no_results_html);
      return this.form_field_jq.trigger('chosen:no_results', {
        chosen: this,
      });
    };

    Chosen.prototype.no_results_clear = function() {
      return this.search_results.find('.no-results').remove();
    };

    Chosen.prototype.keydown_arrow = function() {
      let next_sib;
      if (this.results_showing && this.result_highlight) {
        next_sib = this.result_highlight.nextAll('li.active-result').first();
        if (next_sib) {
          return this.result_do_highlight(next_sib);
        }
      } else {
        return this.results_show();
      }
    };

    Chosen.prototype.keyup_arrow = function() {
      let prev_sibs;
      if (!this.results_showing && !this.is_multiple) {
        return this.results_show();
      }
      if (this.result_highlight) {
        prev_sibs = this.result_highlight.prevAll('li.active-result');
        if (prev_sibs.length) {
          return this.result_do_highlight(prev_sibs.first());
        }
        if (this.choices_count() > 0) {
          this.results_hide();
        }
        return this.result_clear_highlight();
      }
    };

    Chosen.prototype.keydown_backstroke = function() {
      let next_available_destroy;
      if (this.pending_backstroke) {
        this.choice_destroy(this.pending_backstroke.find('a').first());
        return this.clear_backstroke();
      }
      next_available_destroy = this.search_container
        .siblings('li.search-choice')
        .last();
      if (
        next_available_destroy.length &&
        !next_available_destroy.hasClass('search-choice-disabled')
      ) {
        this.pending_backstroke = next_available_destroy;
        if (this.single_backstroke_delete) {
          return this.keydown_backstroke();
        }
        return this.pending_backstroke.addClass('search-choice-focus');
      }
    };

    Chosen.prototype.clear_backstroke = function() {
      if (this.pending_backstroke) {
        this.pending_backstroke.removeClass('search-choice-focus');
      }
      return (this.pending_backstroke = null);
    };

    Chosen.prototype.search_field_scale = function() {
      let div;
      let style;
      let style_block;
      let styles;
      let width;
      let _i;
      let _len;
      if (!this.is_multiple) {
        return;
      }
      style_block = {
        position: 'absolute',
        left: '-1000px',
        top: '-1000px',
        display: 'none',
        whiteSpace: 'pre',
      };
      styles = [
        'fontSize',
        'fontStyle',
        'fontWeight',
        'fontFamily',
        'lineHeight',
        'textTransform',
        'letterSpacing',
      ];
      for (_i = 0, _len = styles.length; _i < _len; _i++) {
        style = styles[_i];
        style_block[style] = this.search_field.css(style);
      }
      div = $('<div />').css(style_block);
      div.text(this.get_search_field_value());
      $('body').append(div);
      width = div.width() + 25;
      div.remove();
      if (this.container.is(':visible')) {
        width = Math.min(this.container.outerWidth() - 10, width);
      }
      return this.search_field.width(width);
    };

    Chosen.prototype.trigger_form_field_change = function(extra) {
      this.form_field_jq.trigger('input', extra);
      return this.form_field_jq.trigger('change', extra);
    };

    return Chosen;
  })(AbstractChosen);
}.call(this));
