/* eslint-disable no-case-declarations */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { decode } from 'html-decoder';
import { useLazyQuery } from 'react-apollo';
import uniq from 'lodash.uniq';
import SearchBarItem from '../components/SearchBar/SearchBarItem';
import {
  gqlSearchQuery,
  LISTINGS_PAGES_QUERY,
  RANDOM_LISTINGS_QUERY,
  RANDOM_REAL_ESTATE_SEARCH,
  REAL_ESTATE_SEARCH_QUERY,
} from '../constants/queries';
import {
  realEstateSearchDefaults,
  rentalSearchDefaults,
  restaurantSearchDefaults,
} from '../constants/search_defaults';
import community_list from '../constants/community_list.json';
import properties_list from '../constants/properties_list.json';
import city_names from '../constants/city_names.json';
import generateNumberBetween from '../utils/generateNumberBetween';
import getUniqUrl from '../utils/getUniqUrl';

export const SearchContext = React.createContext();

const formatCategories = category => ({
  name: category.name,
  slug: category.slug,
  category_is_type: category.is_type,
});

export default function SearchProvider({
  children,
  restaurant_categories,
  all_else_categories,
}) {
  const defaultRestaurantCategories = useMemo(
    () => restaurant_categories.map(formatCategories),
    [restaurant_categories]
  );
  const defaultAllElseCategories = useMemo(
    () => all_else_categories.map(formatCategories),
    [all_else_categories]
  );

  const [searchValue, setSearchValue] = useState('');
  const [selectedFilter, setFilter] = useState('rentals');
  const [response, setResponse] = useState([]);
  const [showDefaults, setShowDefaults] = useState(true);

  const isTypeParam = selectedFilter === 'restaurants' && 'restaurants';
  const isTypeInParam =
    typeof selectedFilter !== 'string' && 'activities,shopping,services';

  const [getRestaurantPages, { data: restaurantsPagesData }] = useLazyQuery(
    LISTINGS_PAGES_QUERY
  );
  const [getAllElsePages, { data: listingsPagesData }] = useLazyQuery(
    LISTINGS_PAGES_QUERY
  );
  const [getRandomRestaurants, { data: randomRestaurantsData }] = useLazyQuery(
    RANDOM_LISTINGS_QUERY
  );
  const [getRandomListings, { data: randomListingsData }] = useLazyQuery(
    RANDOM_LISTINGS_QUERY
  );
  const [getRandomRealEstate, { data: randomRealEstateData }] = useLazyQuery(
    RANDOM_REAL_ESTATE_SEARCH
  );

  const onSearchFocus = async () => {
    if (listingsPagesData || restaurantsPagesData) {
      const MAX_RESTAURANTS_PAGE = parseInt(
        restaurantsPagesData.listings.total_pages
      );
      const MAX_LISTINGS_PAGE = parseInt(
        listingsPagesData.listings.total_pages
      );
      const randomRestaurantsPage =
        Math.floor(Math.random() * (1 + MAX_RESTAURANTS_PAGE - 1)) + 1;
      const randomListingsPage =
        Math.floor(Math.random() * (1 + MAX_LISTINGS_PAGE - 1)) + 1;

      getRandomRestaurants({
        variables: {
          per: '3',
          page: randomRestaurantsPage.toString(),
          is_type_in: ['restaurants'],
        },
      });
      getRandomListings({
        variables: {
          per: '3',
          page: randomListingsPage.toString(),
          is_type_in: ['services', 'activities', 'shopping'],
        },
      });
    }
    getRandomRealEstate({ variables: { per: '4', random: true } });
  };

  useEffect(() => {
    if (!listingsPagesData || !restaurantsPagesData) {
      getRestaurantPages({
        variables: { is_type_in: ['restaurants'] },
      });
      getAllElsePages({
        variables: { is_type_in: ['services', 'activities', 'shopping'] },
      });
    }
  }, [
    getAllElsePages,
    getRestaurantPages,
    listingsPagesData,
    restaurantsPagesData,
  ]);

  // Set Search Value and Selected Filter on mount
  useEffect(() => {
    const search_value = window.localStorage.getItem('search_value');
    const is_type = window.localStorage.getItem('is_type');
    const parsedType =
      is_type && is_type.includes(',')
        ? ['activities', 'shopping', 'services']
        : is_type;
    setSearchValue(search_value !== null ? search_value : '');
    setFilter(parsedType || 'rentals');
    if (search_value) setShowDefaults(false);
  }, [setFilter, setSearchValue]);

  // Change localStorage is_type when selectedFilter changes
  useEffect(() => {
    window.localStorage.setItem('is_type', selectedFilter);
  }, [selectedFilter]);

  // Set filter on mount
  useEffect(() => {
    const queries = window.location.search.slice(1).split('&');
    if (queries.some(q => q.includes('rentals'))) setFilter('rentals');
    else if (queries.some(q => q.includes('is_type_in='))) {
      const queryIsType = queries
        .find(q => q.includes('is_type'))
        .split('=')[1];
      setFilter(
        queryIsType.includes(',') ? queryIsType.split(',') : queryIsType
      );
    }
  }, [setFilter]);

  const resetSearchValue = useCallback(() => {
    const activeItem = document.querySelector('.search-bar-item.active');
    if (activeItem) activeItem.classList.remove('active');
  }, []);

  const handleSubmit = async (value, searchType) => {
    const updatedValue = value ? value.split(' ').join('-') : '';
    const tokenElement = document.querySelector('[name=csrf-token]');

    let updatedSearchType = searchType;
    if (searchType === 'rentals') updatedSearchType = 'properties';
    if (searchType === 'restaurants') updatedSearchType = 'restaurants';
    if (Array.isArray(searchType)) updatedSearchType = 'other';

    const isRealEstate = searchType === 'real_estate';

    if (updatedValue) {
      // MAKE API CALL HERE
      const variables = { title_cont: updatedValue };
      if (!isRealEstate) variables.search_type = updatedSearchType;

      try {
        const res = await axios.post(
          `/graphql`,
          {
            query: isRealEstate ? REAL_ESTATE_SEARCH_QUERY : gqlSearchQuery,
            variables,
          },
          {
            headers: {
              'X-CSRF-Token': tokenElement ? tokenElement.content : '',
            },
          }
        );

        if (res.status === 200) {
          if (isRealEstate) {
            if (res.data.data.mls_search) {
              const filteredResponse = [
                ...res.data.data.mls_search.areas.slice(0, 4),
                ...res.data.data.mls_search.zipcodes.slice(0, 4),
                ...res.data.data.mls_search.mls_listings.slice(0, 4),
              ];
              setResponse(filteredResponse);
            }
          } else if (res.data.data.search) {
            const filteredResponse = [
              ...res.data.data.search.cities,
              ...res.data.data.search.communities.slice(0, 2),
              ...res.data.data.search.categories.slice(0, 2),
              ...res.data.data.search.properties.slice(0, 4),
              ...res.data.data.search.listings.slice(0, 4),
            ];
            setResponse(filteredResponse);
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  // Handle Input
  const handleInput = e => {
    setShowDefaults(false);
    setSearchValue(typeof e === 'string' ? e : e.target.value);
  };

  // Handle Focus
  const handleFocus = () => {
    if (searchValue) {
      handleInput(searchValue);
      handleSubmit(searchValue);
    }
  };

  const handleSearch = () => {
    const { origin } = window.location;
    const { search_path, for_sale_path } = window.Routes;
    if (searchValue) {
      window.localStorage.setItem('search_value', searchValue);
    }
    if (selectedFilter !== 'rentals') {
      window.localStorage.setItem('is_type', selectedFilter);
    }

    // Search Value
    if (selectedFilter === 'real_estate') {
      window.location.assign(
        `${origin}${for_sale_path()}?address_contains=${encodeURI(searchValue)}`
      );
    } else {
      window.location.assign(
        `${origin}${search_path()}?map_type=${
          selectedFilter === 'rentals' ? 'rentals' : 'listings'
        }${
          // eslint-disable-next-line no-nested-ternary
          selectedFilter !== 'rentals'
            ? `&is_type_in=${
                isTypeParam ? selectedFilter.toLowerCase() : isTypeInParam
              }`
            : ''
        }${searchValue && `&title_cont=${searchValue.split(' ').join('_')}`}`
      );
    }
  };

  // Handle dropdown defaults
  useEffect(() => {
    if (showDefaults) {
      switch (selectedFilter) {
        case `rentals`:
          const randomCommunityIndexes = [];
          const randomPropertyIndexes = [];

          // Recursive function to generate 3 unique indexes from community_list
          const pushToCommunityIndexes = () => {
            const randomNumber = generateNumberBetween(
              1,
              community_list.length
            );
            if (randomCommunityIndexes.includes(randomNumber))
              pushToCommunityIndexes();
            else randomCommunityIndexes.push(randomNumber);
          };

          // Recursive function to generate 3 unique indexes from properties_list
          const pushToPropertyIndexes = () => {
            const randomNumber = generateNumberBetween(
              1,
              properties_list.length
            );
            if (randomPropertyIndexes.includes(randomNumber))
              pushToCommunityIndexes();
            else randomPropertyIndexes.push(randomNumber);
          };

          for (let i = 0; i < 3; i += 1) {
            pushToCommunityIndexes();
            pushToPropertyIndexes();
          }

          const defaultRentalsResponse = [
            ...rentalSearchDefaults,
            ...community_list.filter((_, ind) =>
              randomCommunityIndexes.includes(ind)
            ),
            ...properties_list.filter((_, ind) =>
              randomPropertyIndexes.includes(ind)
            ),
          ];

          setResponse(defaultRentalsResponse);
          break;

        case `restaurants`:
          if (randomRestaurantsData && randomRestaurantsData.listings) {
            const defaultRestaurantsResponse = [
              ...defaultRestaurantCategories,
              ...restaurantSearchDefaults,
            ];
            setResponse(
              defaultRestaurantsResponse.concat(
                randomRestaurantsData.listings.collection
              )
            );
          }
          break;

        case `real_estate`:
          if (randomRealEstateData && randomRealEstateData.mls_listings) {
            const defaultRealEstateResponse = [...realEstateSearchDefaults];
            setResponse(
              defaultRealEstateResponse.concat(
                randomRealEstateData.mls_listings.collection
              )
            );
          }
          break;

        default:
          if (randomListingsData && randomListingsData.listings) {
            const defaultListingsResponse = [
              ...defaultAllElseCategories,
              ...restaurantSearchDefaults,
            ];
            setResponse(
              defaultListingsResponse.concat(
                randomListingsData.listings.collection
              )
            );
          }
          break;
      }
    }
  }, [
    defaultAllElseCategories,
    defaultRestaurantCategories,
    randomListingsData,
    randomRealEstateData,
    randomRestaurantsData,
    selectedFilter,
    showDefaults,
  ]);

  const buildResults = responseResults => {
    const suggestedText = {
      cities: false,
      communities: false,
      listings: false,
      properties: false,
      categories: false,
      areas: false,
      zipcodes: false,
      addresses: false,
    };

    const isRealEstate = selectedFilter === 'real_estate';

    return responseResults
      .filter(item => {
        // Remove Listings from results if on rentals tab
        if (
          selectedFilter === 'rentals' &&
          (item.is_type || item.category_image_url)
        )
          return false;
        // Remove Rentals from results if on a listings tab
        if (selectedFilter !== 'rentals' && item.property_url) return false;
        if (
          selectedFilter === 'restaurants' &&
          item.is_type !== 'restaurants' &&
          item.is_type
        )
          return false;
        if (
          selectedFilter !== 'restaurants' &&
          selectedFilter !== 'rentals' &&
          item.is_type === 'restaurants'
        )
          return false;
        return true;
      })
      .map((item, ind) => {
        const isRental = selectedFilter === 'rentals';
        const searchQueries = window.location.search.substr(1).split('&');
        const grabQueryParams = () => {
          if (isRental) {
            return searchQueries.filter(
              q =>
                q.includes('check_in') ||
                q.includes('check_out') ||
                q.includes('guests') ||
                q.includes('min_price') ||
                q.includes('max_price') ||
                q.includes('beds') ||
                q.includes('baths') ||
                q.includes('property_type') ||
                q.includes('amenities') ||
                q.includes('is_premier') ||
                q.includes('rentals_sort_by')
            );
          }
          return searchQueries.filter(
            q =>
              q.includes('is_type') ||
              q.includes('is_type_in') ||
              q.includes('categories') ||
              q.includes('is_premier') ||
              q.includes('listings_sort_by')
          );
        };
        const appendedSearchQueries = uniq(grabQueryParams()).join('&');

        // Cities
        if (item.hasOwnProperty('video_code')) {
          let citiesHref = `/${item.name}`;
          if (isRental) citiesHref = `/${item.name}/vacation-rentals`;
          if (selectedFilter === 'restaurants')
            citiesHref = `/${item.name}/restaurants`;

          if (!suggestedText.cities) {
            suggestedText.cities = true;
            const suggestedType = isRental
              ? 'Rentals'
              : isTypeParam || 'Businesses';
            return (
              <div key={ind}>
                <p className="suggested-topics">{`Find ${suggestedType
                  .charAt(0)
                  .toUpperCase() + suggestedType.slice(1)} by City`}</p>
                <SearchBarItem
                  selectedFilter={selectedFilter}
                  href={citiesHref}
                  is_type="cities"
                >
                  {decode(item.name.split('-').join(' '))}
                </SearchBarItem>
              </div>
            );
          }

          return (
            <SearchBarItem
              selectedFilter={selectedFilter}
              key={ind}
              href={citiesHref}
              is_type="cities"
            >
              {decode(item.name.split('-').join(' '))}
            </SearchBarItem>
          );
        }
        // Communities
        if (item.hasOwnProperty('property_count')) {
          const area = city_names[item.city_id];
          const communitySearchRoute = `${window.Routes.search_path()}?map_type=listings&comm_id=${
            item.id
          }${appendedSearchQueries ? `&${appendedSearchQueries}` : ''}`;
          const uniqHref = getUniqUrl(communitySearchRoute);
          const uniqHrefCondensed = [];
          uniqHref.split('&').forEach(q => {
            const [qName] = q.split('=');
            if (!uniqHrefCondensed.some(query => query.includes(qName))) {
              uniqHrefCondensed.push(q);
            }
          });

          const communitiesHref = isRental
            ? `/${area}/vacation-rentals/${item.slug}`
            : uniqHrefCondensed.join('&');

          if (!suggestedText.communities) {
            suggestedText.communities = true;
            return (
              <div key={ind}>
                <p className="suggested-topics">Suggested Communities</p>
                <SearchBarItem
                  selectedFilter={selectedFilter}
                  key={ind}
                  href={communitiesHref}
                  is_type="cities"
                >
                  {decode(item.name.split('-').join(' '))}
                </SearchBarItem>
              </div>
            );
          }

          return (
            <SearchBarItem
              selectedFilter={selectedFilter}
              key={ind}
              href={communitiesHref}
              is_type="cities"
            >
              {decode(item.name.split('-').join(' '))}
            </SearchBarItem>
          );
        }

        // Listings
        if (item.hasOwnProperty('category')) {
          if (!suggestedText.listings) {
            suggestedText.listings = true;
            const listingType = item.is_type;
            return (
              <div key={ind}>
                <p className="suggested-topics">{`Suggested ${
                  listingType === 'restaurants' ? 'Restaurants' : 'Businesses'
                } By Name`}</p>
                <SearchBarItem
                  selectedFilter={selectedFilter}
                  key={ind}
                  href={window.Routes.listing_type_show_path({
                    area: item.city_name,
                    is_type: item.is_type,
                    category: encodeURI(item.category.slug).toLowerCase(),
                    url: item.slug,
                  })}
                  is_type={item.is_type}
                  category_image_url={item.category_image_url}
                >
                  {decode(item.title.split('-').join(' '))}
                </SearchBarItem>
              </div>
            );
          }

          return (
            <SearchBarItem
              selectedFilter={selectedFilter}
              key={ind}
              href={window.Routes.listing_type_show_path({
                area: item.city_name,
                is_type: item.is_type,
                category: encodeURI(item.category.slug).toLowerCase(),
                url: item.slug,
              })}
              is_type={item.is_type}
              category_image_url={item.category_image_url}
            >
              {decode(item.title.split('-').join(' '))}
            </SearchBarItem>
          );
        }

        // Properties
        if (item.hasOwnProperty('property_url') && isRental) {
          if (!suggestedText.properties) {
            suggestedText.properties = true;
            return (
              <div key={ind}>
                <p className="suggested-topics">Suggested Rentals By Name</p>
                <SearchBarItem
                  selectedFilter={selectedFilter}
                  key={ind}
                  href={item.property_url}
                  is_type="rentals"
                >
                  {decode(item.name.split('-').join(' '))}
                </SearchBarItem>
              </div>
            );
          }

          return (
            <SearchBarItem
              selectedFilter={selectedFilter}
              key={ind}
              href={item.property_url}
              is_type="rentals"
            >
              {decode(item.name.split('-').join(' '))}
            </SearchBarItem>
          );
        }

        // MLS Areas
        if (isRealEstate && item.hasOwnProperty('name')) {
          if (!suggestedText.areas) {
            suggestedText.areas = true;
            return (
              <div key={ind}>
                <p className="suggested-topics">Find homes for sale by area</p>
                <SearchBarItem
                  selectedFilter={selectedFilter}
                  href={window.Routes.for_sale_path({
                    areas: item.slug,
                  })}
                  is_type="areas"
                >
                  {item.name}
                </SearchBarItem>
              </div>
            );
          }

          return (
            <SearchBarItem
              selectedFilter={selectedFilter}
              href={window.Routes.for_sale_path({
                areas: item.slug,
              })}
              is_type="areas"
              key={ind}
            >
              {item.name}
            </SearchBarItem>
          );
        }

        // MLS Zipcodes
        if (isRealEstate && item.hasOwnProperty('postal_code')) {
          if (!suggestedText.zipcodes) {
            suggestedText.zipcodes = true;
            return (
              <div key={ind}>
                <p className="suggested-topics">
                  Find homes for sale by ZIP code
                </p>
                <SearchBarItem
                  selectedFilter={selectedFilter}
                  href={window.Routes.for_sale_path({
                    zipcode: item.postal_code,
                  })}
                  is_type="zipcodes"
                >
                  {item.postal_code}, {item.city}, {item.state}
                </SearchBarItem>
              </div>
            );
          }

          return (
            <SearchBarItem
              selectedFilter={selectedFilter}
              href={window.Routes.for_sale_path({
                zipcode: item.postal_code,
              })}
              is_type="zipcodes"
              key={ind}
            >
              {item.postal_code}, {item.city}, {item.state}
            </SearchBarItem>
          );
        }

        // MLS Addresses
        if (isRealEstate && item.hasOwnProperty('address')) {
          if (!suggestedText.addresses) {
            suggestedText.addresses = true;
            return (
              <div key={ind}>
                <p className="suggested-topics">
                  Find homes for sale by address
                </p>
                <SearchBarItem
                  selectedFilter={selectedFilter}
                  href={window.Routes.for_sale_path({
                    listing_url: item?.address_show_url,
                  })}
                  is_type="address"
                >
                  {item.address}
                </SearchBarItem>
              </div>
            );
          }

          return (
            <SearchBarItem
              selectedFilter={selectedFilter}
              href={window.Routes.for_sale_path({
                listing_url: item?.address_show_url,
              })}
              is_type="address"
              key={ind}
            >
              {item.address}
            </SearchBarItem>
          );
        }

        // Categories
        if (
          (selectedFilter !== 'rentals' || selectedFilter !== 'real_estate') &&
          item.name
        ) {
          const categoryisType = item.category_is_type
            ? item.category_is_type
            : item.is_type;

          if (!suggestedText.categories) {
            suggestedText.categories = true;
            return (
              <div key={ind}>
                <p className="suggested-topics">Suggested Categories</p>
                <SearchBarItem
                  selectedFilter={selectedFilter}
                  key={ind}
                  href={`/${categoryisType}/${item.slug}`}
                  is_type="categories"
                >
                  {decode(item.name.split('-').join(' '))}
                </SearchBarItem>
              </div>
            );
          }
          return (
            <SearchBarItem
              selectedFilter={selectedFilter}
              key={ind}
              href={`/${categoryisType}/${item.slug}`}
              is_type="categories"
            >
              {decode(item.name.split('-').join(' '))}
            </SearchBarItem>
          );
        }
        return null;
      });
    // .slice(0, 10);
  };

  return (
    <SearchContext.Provider
      value={{
        searchValue,
        setSearchValue,
        selectedFilter,
        setFilter,
        response,
        setResponse,
        buildResults,
        resetSearchValue,
        handleSubmit,
        handleInput,
        handleSearch,
        showDefaults,
        setShowDefaults,
        handleFocus,
        onSearchFocus,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

SearchProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  all_else_categories: PropTypes.array.isRequired,
  restaurant_categories: PropTypes.array.isRequired,
};
