import { useState, useEffect, useCallback } from 'react';

/**
 * Get the number of slides that fit inside of a slider
 * @param {ref} sliderRef - The ref to the slider container.
 * @param {number} cardWidth - The cards minimum width (including padding).
 * @return {number} The maximum number of slides that fit within the slider.
 */

export default function useSlides({ sliderRef, cardWidth }, dependencies = []) {
  if (!sliderRef) console.warn(`sliderRef does not exist`);
  if (!cardWidth) console.warn(`cardWidth does not exist`);

  const [slideCount, setSlides] = useState(null);

  const calcSlidesCallback = useCallback(() => {
    if (sliderRef && sliderRef.current && sliderRef.current.clientWidth) {
      setSlides(Math.floor(sliderRef.current.clientWidth / cardWidth));
    }
  }, [cardWidth, sliderRef]);

  // componentDidMount (Used to set the initial slideCount)
  useEffect(() => {
    calcSlidesCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcSlidesCallback, ...dependencies]);

  useEffect(() => {
    window.addEventListener('resize', calcSlidesCallback);

    return () => {
      window.removeEventListener('resize', calcSlidesCallback);
    };
  }, [calcSlidesCallback, cardWidth, sliderRef]);

  return slideCount || 1;
}
