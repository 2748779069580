import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import GuideCard from '../GuideCard';
import useSlides from '../../hooks/useSlides';
import randomize from '../../utils/randomize';
import useWindowSize from '../../hooks/useWindowSize';

export default function TTDCards({ guides }) {
  const containerRef = useRef(null);

  const { width } = useWindowSize();

  const TTD_CARD_WIDTH = width <= 768 ? 220 : 348;

  const slideCount = useSlides({
    sliderRef: containerRef,
    cardWidth: TTD_CARD_WIDTH,
  });

  const [randomizedGuides, setRandomizedGuides] = useState(guides);
  useEffect(() => {
    setRandomizedGuides(randomize(guides));
  }, [guides]);

  return (
    <div className="ttd-cards-container" ref={containerRef}>
      {randomizedGuides
        .map(guide => <GuideCard key={guide.id} guide={guide} />)
        .slice(0, slideCount)}
    </div>
  );
}

TTDCards.propTypes = {
  guides: PropTypes.array.isRequired,
};
