import React, { useContext, useRef, useEffect } from 'react';
import searchIcon from 'search.svg';
import timesIcon from 'times.svg';
import { DebounceInput } from 'react-debounce-input';
import { SearchContext } from '../context/SearchContext';

export default function SearchPage() {
  const {
    searchValue,
    setSearchValue,
    selectedFilter,
    setFilter,
    response,
    setResponse,
    buildResults,
    resetSearchValue,
    handleSubmit,
    handleInput,
    handleSearch,
    handleFocus,
    onSearchFocus,
  } = useContext(SearchContext);

  const searchPageRef = useRef(null);

  useEffect(() => {
    const clickHandler = () => {
      if (!searchPageRef.current.classList.contains('hidden')) {
        onSearchFocus();
        document
          .querySelector('#header-search')
          .removeEventListener('click', clickHandler);
      }
    };

    document
      .querySelector('#header-search')
      .addEventListener('click', clickHandler);
  }, [onSearchFocus, searchPageRef]);

  return (
    <div ref={searchPageRef} className="mobile-search hidden" id="search-page">
      <header className="search-header">
        <form
          className="input-container"
          onSubmit={e => {
            e.preventDefault();
            handleSearch();
          }}
        >
          <DebounceInput
            aria-label="Search Input"
            value={searchValue}
            onFocus={handleFocus}
            debounceTimeout={300}
            onChange={e => {
              e.persist();
              handleInput(e);
              handleSubmit(e.target.value, selectedFilter);
            }}
          />
          <img
            data-src={searchIcon}
            alt=""
            className="search-icon lazyload"
            title="Search Icon"
          />
          <button
            aria-label="Clear Search"
            name="Clear Search"
            type="button"
            className="close-icon"
            onClick={e => {
              e.preventDefault();
              setSearchValue('');
              handleSubmit('');
            }}
          >
            <img
              data-src={timesIcon}
              alt=""
              title="Search Close Icon"
              className="lazyload"
            />
          </button>
        </form>
        <button
          aria-label="Close Search Page"
          name="Close Search Page"
          type="button"
          id="close-search-page"
          className="secondary-text"
          onClick={() => {
            // Used to delay UI update until animation is finished
            setTimeout(() => {
              resetSearchValue();
              handleSubmit('');
              setResponse([]);
            }, 205);
          }}
        >
          Cancel
        </button>
      </header>

      <section className="search-type-container">
        <button
          aria-label="Search Rentals"
          name="Search Rentals"
          onClick={() => {
            setFilter('rentals');
            handleSubmit(searchValue, 'properties');
          }}
          type="button"
          className={`btn filter-button btn--light ${
            selectedFilter === 'rentals' ? 'btn--dark' : 'btn-clear'
          }`}
        >
          Stay
        </button>
        <button
          aria-label="Search Real Estate"
          name="Search Real Estate"
          onClick={() => {
            setFilter('real_estate');
            handleSubmit(searchValue, 'real_estate');
          }}
          type="button"
          className={`btn filter-button btn--light ${
            selectedFilter === 'real_estate' ? 'btn--dark' : 'btn-clear'
          }`}
        >
          Buy
        </button>
        <button
          aria-label="Search Restaurants"
          name="Search Restaurants"
          onClick={() => {
            setFilter('restaurants');
            handleSubmit(searchValue, 'restaurants');
          }}
          type="button"
          className={`btn filter-button ${
            selectedFilter === 'restaurants' ? 'btn--dark' : 'btn--light'
          }`}
        >
          Dine
        </button>
        <button
          aria-label="Search Services"
          name="Search Services"
          onClick={() => {
            setFilter(['activities', 'shopping', 'services']);
            handleSubmit(searchValue, 'other');
          }}
          type="button"
          className={`btn filter-button ${
            typeof selectedFilter !== 'string' ? 'btn--dark' : 'btn--light'
          }`}
        >
          All Else
        </button>
      </section>

      <section className="search-bar-items-wrapper mobile">
        {buildResults(response)}
      </section>
    </div>
  );
}
