import generateNumberBetween from './generateNumberBetween';

export default function randomize(originalArr) {
  const randomIndexes = [];

  const runIndexGenerator = () => {
    const newIndex = generateNumberBetween(0, originalArr.length);
    if (randomIndexes.includes(newIndex)) runIndexGenerator();
    else {
      randomIndexes.push(newIndex);
    }
  };

  originalArr.forEach(runIndexGenerator);

  const randomizedArr = [];
  randomIndexes.forEach(newInd => {
    randomizedArr.push(originalArr[newInd]);
  });

  return randomizedArr;
}
