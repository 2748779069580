import { useState, useEffect } from 'react';

/**
 * useWindowSize
 *
 * @returns {
 *  height: Int -  Current window height
 *  width: Int - Current window width
 * }
 */
const useWindowSize = () => {
  const windowExists = typeof window !== 'undefined';
  const [height, setHeight] = useState(
    windowExists ? window.innerHeight : null
  );
  const [width, setWidth] = useState(windowExists ? window.innerWidth : null);

  const handleResize = () => {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    // add event listener for resize on mount
    window.addEventListener('resize', handleResize);

    // clean up listener after
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    height,
    width,
  };
};

export default useWindowSize;
