import React from 'react';
import PropTypes from 'prop-types';

export default function FiltersContainer({ children }) {
  return <div className="filters-container">{children}</div>;
}

FiltersContainer.propTypes = {
  children: PropTypes.array,
};
