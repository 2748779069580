import ApolloClient, { InMemoryCache } from 'apollo-boost';

const isSSR = typeof window === 'undefined';
const tokenElement = isSSR ? '' : document.querySelector('[name=csrf-token]');

export const client = isSSR
  ? {}
  : new ApolloClient({
      uri: '/graphql',
      headers: {
        'X-CSRF-Token': tokenElement ? tokenElement.content : '',
      },
      cache: new InMemoryCache(),
    });

export const clientNoVarnish = isSSR
  ? {}
  : new ApolloClient({
      uri: '/graphql?skip_varnish=true',
      headers: {
        'X-CSRF-Token': tokenElement ? tokenElement.content : '',
      },
      cache: new InMemoryCache(),
    });
