import React, { useState } from 'react';
import DateRangePicker from 'react-dates/lib/components/DateRangePicker';
import 'react-dates/initialize';
import ReactSVG from 'react-svg';
import moment from 'moment';
import customArrow from 'custom_datepicker_arrow.svg';
import DatepickerArrow from './DatepickerArrow';
import { queryDateFormat } from '../constants/formats.json';
import useWindowSize from '../hooks/useWindowSize';
import ErrorBoundary from './ErrorBoundary';

function HeroSearch({ area, community_id, small, homeHero }) {
  const isSSR = typeof window === 'undefined';

  const { width } = useWindowSize();

  const [guests, setGuests] = useState(0);
  const [localStartDate, setLocalStartDate] = useState(null);
  const [localEndDate, setLocalEndDate] = useState(null);
  const [focusedState, setFocusedState] = useState(null);

  let mobileDatesText = 'Dates';
  if (localStartDate && !localEndDate)
    mobileDatesText = `${localStartDate.format('MMM DD')}`;
  else if (localStartDate && localEndDate)
    mobileDatesText = `${localStartDate.format(
      'MMM DD'
    )} - ${localEndDate.format('MMM DD')}`;

  const searchHref = isSSR
    ? ''
    : `${window.Routes.search_path()}?map_type=rentals${
        guests ? `&guests=${guests}` : ''
      }${
        localStartDate
          ? `&check_in=${localStartDate.format(queryDateFormat)}`
          : ''
      }${
        localEndDate ? `&check_out=${localEndDate.format(queryDateFormat)}` : ''
      }${area ? `&area=${area}` : ''}${
        community_id ? `&comm_id=${community_id}` : ''
      }&zoom=10`;

  const validateDates = (startDate, endDate) => {
    if (
      localStartDate &&
      localEndDate &&
      localStartDate.isAfter(localEndDate)
    ) {
      setLocalStartDate(startDate);
      setLocalEndDate(null);
    }
    setLocalStartDate(startDate);
    setLocalEndDate(endDate);
  };

  return (
    <form
      className={`communities-search-container ${small ? 'small' : ''} ${
        homeHero ? 'home-hero' : ''
      }`}
    >
      <section className="input-wrapper">
        <div className="input">
          <select value={guests} onChange={e => setGuests(e.target.value)}>
            <option value={0}>Guests</option>
            {[...Array(15)].map((_, ind) => (
              <option key={ind} value={`${ind + 1}`}>
                {ind + 1}+
              </option>
            ))}
          </select>
        </div>
        <div className="input custom-connected-date-picker custom-calendar-wrapper">
          {/* eslint-disable-next-line */}
          <div
            className="mobile-community-dates"
            onClick={() => setFocusedState('startDate')}
          >
            {mobileDatesText}
          </div>
          <DateRangePicker
            startDate={localStartDate}
            startDateId="property_booking_start_date"
            startDatePlaceholderText="Check In"
            endDate={localEndDate}
            endDateId="property_booking_end_date"
            endDatePlaceholderText="Check Out"
            onDatesChange={({ startDate, endDate }) => {
              validateDates(startDate, endDate);
            }}
            focusedInput={focusedState}
            onFocusChange={e => setFocusedState(e)}
            numberOfMonths={1}
            minimumNights={0}
            noBorder
            navNext={<DatepickerArrow direction="next" />}
            navPrev={<DatepickerArrow direction="prev" />}
            customArrowIcon={<ReactSVG src={customArrow} />}
            isDayBlocked={day => {
              // block all days before today
              if (day.isBefore(moment().format('MM-DD-YYYY'))) {
                return true;
              }
            }}
          />
        </div>
      </section>
      <a
        aria-label={`Search${area ? ` ${area}` : ''}`}
        href={searchHref}
        className="btn btn--blue-grad clear_rental_query_params"
      >
        Search{small || (homeHero && width > 768 && ' Rentals')}
      </a>
    </form>
  );
}

export default function HeroSearchWrapped({ ...props }) {
  return (
    <ErrorBoundary>
      <HeroSearch {...props} />
    </ErrorBoundary>
  );
}
