import { useEffect, useState } from 'react';

/**
 * Get the number of slides that fit inside of a slider
 * @param {ref} sliderRef - The ref to the slider container.
 * @param {number} cardWidth - The cards width (including padding).
 * @return {number} The maximum number of slides that fit within the slider.
 */

export default function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const listenerFunc = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', listenerFunc);

    return () => {
      window.removeEventListener('resize', listenerFunc);
    };
  }, []);

  return windowWidth;
}
