import { setConfig } from 'react-hot-loader';
import Compressor from 'compressorjs';
import { DirectUpload } from 'activestorage';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';

import './global';

setConfig({
  hotHooks: true,
});
// import 'react-toastify/dist/ReactToastify.css';

$(() => {
  // Clear Scroll Lock
  clearAllBodyScrollLocks();
  $('body').css('overflow', '');

  if (
    !window.location.pathname.includes(window.Routes.search_path()) &&
    !window.location.pathname.includes(window.Routes.for_sale_path())
  )
    window.localStorage.removeItem('search_value');
});

if (!window.location.pathname.includes(window.Routes.for_sale_path())) {
  window.localStorage.setItem('previousURL', window.location.pathname);
}

const compressImage = file => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-new
    new Compressor(file, {
      convertSize: 1000000, // 1MB
      success: result => {
        resolve(new File([result], file.name, { type: result.type }));
      },
      error: error => reject(error),
    });
  });
};

// Handle New Review Photos
$(() => {
  const uploadInput = $('#review_photos_handler');

  $('.new-review-upload-btn').click(e => {
    e.preventDefault();
    e.stopPropagation();
    uploadInput.trigger('click');
  });

  uploadInput.change(() => {
    const url = uploadInput[0].dataset.directUploadUrl;
    const images = Array.from(uploadInput[0].files);

    const compressPromises = [];

    images.forEach(image => compressPromises.push(compressImage(image)));

    Promise.all(compressPromises)
      .then(compressedFiles => {
        compressedFiles.forEach((file, index) => {
          const upload = new DirectUpload(file, url);
          // 1 = 1MB
          upload.create((error, blob) => {
            if (error) {
              console.error(error);
            } else {
              $('<input>')
                .attr({
                  type: 'hidden',
                  name: 'review[photos]',
                  value: blob.signed_id,
                  id: `review-image-${index}`,
                  class: 'review-image',
                })
                .appendTo('#new_review');
            }
          });

          const imageURL = URL.createObjectURL(file);
          $('.modal-images-preview-wrapper.new-review').append(`
            <div>
              <section class="delete-image">
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <line x1="0.646447" y1="12.6464" x2="12.6464" y2="0.646446" stroke="#073655"/>
                  <line y1="-0.5" x2="16.9706" y2="-0.5" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 12 13)" stroke="#073655"/>
                </svg>
              </section>
              <img src="${imageURL}" alt="${file.name}" title="Review Image" />
            </div>
          `);
        });
      })
      .catch(error => console.log('ooops :(', error));

    Array.from($('.delete-image')).forEach((elem, index) => {
      $(elem).click(() => {
        $(`input#review-image-${index}`).remove();
        $(elem)[0].parentElement.remove();
      });
    });
  });
});

// append the current pathname to the sign in button to allow easier server-side redirects after sign in
$(() => {
  // find every auth link on the page
  const authLinks = document.querySelectorAll(
    '[href="/login"], [href="/sign-up"], [href="/users/auth/google_oauth2"], [href="/users/auth/facebook"]'
  );

  // and add the current pathname
  authLinks.forEach(element => {
    element.href += `?redirect_url=${encodeURI(window.location.pathname)}`;
  });

  const oauthLinks = document.querySelectorAll(
    '[href="/users/auth/google_oauth2?fetch_param=true"], [href="/users/auth/facebook?fetch_param=true"], [href="/sign-up?fetch_param=true"], [href="/login?fetch_param=true"]'
  );

  oauthLinks.forEach(element => {
    element.href += `${encodeURI(window.location.search.replace('?', '&'))}`;
  });
});
